export default {
  data () {
    return {
      search: {
        byId: '',
        byAny: '',
        filter: '',
        options: {
          debounce: 500,
          enableIdSearch: true // right now always true, disable by not using it all
        }
      }
    }
  },
  watch: {
    'search.byId': function () {
      if (!this.search.options.enableIdSearch) {
        return
      }
      if (this.searchEmpty) {
        this.search.filter = ''
        return
      }
      if (this.search.byId) {
        this.search.byAny = ''
        this.search.filter = 'id:' + this.search.byId
      }
    },
    'search.byAny': function () {
      if (this.searchEmpty) {
        this.search.filter = ''
        return
      }
      if (this.search.byAny) {
        this.search.byId = ''
        this.search.filter = this.search.byAny
      }
    }
  },
  computed: {
    searchEmpty () {
      return this.search.options.enableIdSearch
        ? this.search.byId === '' && this.search.byAny === ''
        : this.search.byAny === ''
    }
  },
  methods: {
    clearSearch () {
      this.search.byId = ''
      this.search.byAny = ''
      this.search.search = ''
    }
  }
}

<template>
  <div class="card">
    <div class="card-header">Importer Status</div>
    <div class="card-body">
      <b-alert v-if="error" variant="danger" show>
        <h6>Importer Fehler</h6>
        <div>
          {{ error }}
        </div>
      </b-alert>
      <div v-else>
        <div class="row">
          <div class="col-12 text-right mb-2">
            <router-link v-if="linkPage" :to="{ name: 'Oi-Importer' }">zur Importseite gehen</router-link>
          </div>
          <div class="col-12">
            <b-table-lite :fields="fields" :items="stats">
              <template v-slot:cell(status)="">
                <b-badge :variant="statusVariant">{{ status | formatStatus }}</b-badge>
              </template>
            </b-table-lite>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'importer-stats',
  props: {
    linkPage: Boolean
  },
  data () {
    return {
      error: null,
      status: 'unknown',
      numJobs: 0,
      interval: 0,
      pending: 0,
      fields: [
        {
          key: 'status',
          label: 'Status'
        },
        {
          key: 'numJobs',
          label: 'Jobs'
        },
        {
          key: 'interval',
          label: 'Intervall'
        },
        {
          key: 'pending',
          label: 'Ausstehende Importe'
        }
      ]
    }
  },
  computed: {
    stats () {
      return [
        {
          status: this.status,
          numJobs: this.numJobs,
          interval: this.interval,
          pending: this.pending
        }
      ]
    },
    statusVariant () {
      switch (this.status) {
        case 'not running':
          return 'danger'
        case 'running':
          return 'success'
        case 'unknown':
          return 'dark'
        default:
          return 'warning'
      }
    }
  },
  filters: {
    formatStatus (value) {
      switch (value) {
        case 'not running':
          return 'Gestoppt'
        case 'running':
          return 'Läuft'
        case 'unknown':
          return 'Unbekannt'
        default:
          return value
      }
    }
  },
  methods: {
    getImporterStats () {
      axios.get('/import_jobs/status')
        .then(response => {
          if (response.data.Status) {
            this.status = response.data.Status
            this.numJobs = response.data.NumJobs
            this.interval = response.data.Interval
            this.pending = response.data.Workers.length
          } else {
            this.status = response.data
            this.numJobs = 0
            this.interval = 0
            this.pending = 0
          }
        })
        .catch(error => {
          console.log(error)
          this.error = error.response || error
          this.status = 'stopped'
        })
    }
  },
  mounted () {
    this.getImporterStats()
  }
}
</script>

<template>
  <div class="card">
    <div class="card-header">
      Ausstehende Domains
    </div>
    <div class="card-body">
      <b-tabs>
        <b-tab title="In Bearbeitung">
          <div class="row">
            <div class="col-12">
              <b-form-checkbox
                id="checkbox-1"
                v-model="refresh"
                name="checkbox-1"
                value="refresh"
                unchecked-value="no_refresh"
                class="float-right"
                @change="toggleRefresh"
              >
                Auto Refresh
              </b-form-checkbox>
            </div>
            <div class="col-12">
              <b-table id="table-1" ref="table-1"
                       :fields="workers.fields" :items="workerItems"
                       striped hover selectable select-mode="single"
                       :sort-by.sync="searchOptions.sortBy" :sort-desc.sync="searchOptions.sortDesc"
                       :filter.sync="searchOptions.search" :current-page="searchOptions.currentPage"
                       no-provider-paging no-provider-sorting no-provider-filtering>
                <template v-slot:cell(Id)="data">
                  {{ data.item.CurrentDomain.Id }}
                  <b-button @click="deleteRunning(data.item)" variant="danger" size="sm" squared class="float-right"><b-icon-x></b-icon-x></b-button>
                </template>
                <template v-slot:cell(Name)="data">
                  {{ data.item.CurrentDomain.Name }}
                </template>
                <template v-slot:cell(Website)="data">
                  <a :href="data.item.CurrentDomain.Website" target="_blank">{{ data.item.CurrentDomain.Website }}</a>
                </template>
                <template v-slot:cell(Status)="data">
                  {{ data.item.CurrentDomain.Status }}
                </template>
                <template v-slot:cell(Active)="data">
                  <b-icon-check-square-fill v-if="data.item.CurrentDomain.Active" variant="success"></b-icon-check-square-fill>
                  <b-icon-x-square-fill v-else variant="danger"></b-icon-x-square-fill>
                </template>
                <template v-slot:cell(Extractor)="data">
                  {{ data.item.CurrentDomain.Extractor }}
                </template>
                <template v-slot:cell(LastCrawled)="data">
                  {{ new Date(data.item.CurrentDomain.LastCrawled).toLocaleString() }}
                </template>
                <template v-slot:cell(Extractor)="data">
                  {{ data.item.CurrentDomain.Extractor }}
                </template>
                <template v-slot:cell(UpdatedAt)="data">
                  {{ new Date(data.item.CurrentDomain.UpdatedAt).toLocaleString() }}
                </template>
                <template v-slot:cell(CreatedAt)="data">
                  {{ new Date(data.item.CurrentDomain.CreatedAt).toLocaleString() }}
                </template>
                <template v-slot:cell(StartedAt)="data">
                  {{ new Date(data.item.StartedAt).toLocaleString() }}
                </template>
                <template v-slot:row-details="row">
                  <div v-if="row.item.CurrentTask" class="row">
                    <div class="col-12 col-md-2">
                      <strong>{{ row.item.CurrentTask.Process }}</strong><br>
                      <em>{{ ((new Date().getTime() - new Date(row.item.CurrentTask.StartTime).getTime()) / 1000).toFixed(2) }} Sekunden</em>
                    </div>
                    <div class="col-12 col-md-10">
                      <em class="float-right">{{ row.item.CurrentTask.Url }}</em>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </b-tab>
        <b-tab title="Ausstehend">
          <div class="row">
            <div class="col-12 mt-1">
              <b-form-group
                label="Pro Seite"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="10"
                label-align="right"
                label-align-sm="right"
                label-size="sm"
                label-for="perPageSelect"
                class="mb-2 pull-right"
              >
                <b-form-select
                  v-model="itemsPerPage"
                  id="perPageSelect"
                  size="sm"
                  :options="perPageOptions"
                >
                </b-form-select>
              </b-form-group>
            </div>
            <div class="col-12">
              <b-table id="table-2" ref="table-2"
                       :fields="fields" :items="items"
                       :per-page="itemsPerPage" :current-page="searchOptions.currentPage"
                       striped hover selectable select-mode="single"
                       no-provider-filtering no-provider-sorting>
                <template v-slot:cell(Id)="data">
                  {{ data.item.Domain.Id }}
                  <b-button @click="deleteEnqueued(data.item)" variant="danger" size="sm" squared class="float-right"><b-icon-x></b-icon-x></b-button>
                </template>
                <template v-slot:cell(Name)="data">
                  {{ data.item.Domain.Name }}
                </template>
                <template v-slot:cell(Website)="data">
                  <a :href="data.item.Domain.Website" target="_blank">{{ data.item.Domain.Website }}</a>
                </template>
                <template v-slot:cell(Status)="data">
                  {{ data.item.Domain.Status }}
                </template>
                <template v-slot:cell(Active)="data">
                  <b-icon-check-square-fill v-if="data.item.Domain.Active" variant="success"></b-icon-check-square-fill>
                  <b-icon-x-square-fill v-else variant="danger"></b-icon-x-square-fill>
                </template>
                <template v-slot:cell(Priority)="data">
                  {{ data.item.Priority }}
                </template>
                <template v-slot:cell(LastCrawled)="data">
                  {{ new Date(data.item.Domain.LastCrawled).toLocaleString() }}
                </template>
                <template v-slot:cell(UpdatedAt)="data">
                  {{ new Date(data.item.Domain.UpdatedAt).toLocaleString() }}
                </template>
                <template v-slot:cell(CreatedAt)="data">
                  {{ new Date(data.item.Domain.CreatedAt).toLocaleString() }}
                </template>
                <template v-slot:row-details="row">
                  <div class="row">
                    <div class="col-12 col-md-8">
                      <b-input-group>
                        <b-input :id="'remark-' + row.item.Domain.Id" v-model="row.item.Domain.Remark" class="float-left">
                        </b-input>
                      </b-input-group>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="col-12">
              <b-pagination
                class="d-flex"
                v-model="searchOptions.currentPage"
                :total-rows="rowsTotal"
                :per-page="itemsPerPage"
                align="right">
              </b-pagination>
            </div>
          </div>
        </b-tab>
      </b-tabs>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'

export default {
  name: 'Domains',
  components: { },
  data: function () {
    return {
      data: {
        // Records: []
      },
      searchOptions: {
        search: '',
        currentPage: 1,
        sortBy: '',
        sortDesc: false
      },
      fetcherRunning: false,
      refreshInterval: null,
      refresh: '',
      rowsTotal: 0,
      itemsPerPage: 50,
      perPageOptions: [10, 25, 50, 100],
      workers: {
        currentPage: 1,
        rowsTotal: 0,
        itemsPerPage: 50,
        perPageOptions: [10, 25, 50, 100],
        fields: [
          {
            key: 'Index',
            label: 'Index',
            sortable: true
          },
          {
            key: 'Id',
            label: 'Domain ID',
            sortable: true
          },
          {
            key: 'Name',
            label: 'Name',
            sortable: true
          },
          {
            key: 'Website',
            label: 'Website',
            sortable: true
          },
          {
            key: 'Status',
            label: 'Status',
            sortable: true
          },
          {
            key: 'Extractor',
            label: 'Extractor',
            sortable: true
          },
          {
            key: 'Active',
            label: 'Aktiv'
          },
          {
            key: 'PageCount',
            label: 'Seiten gecrawled',
            sortable: true
          },
          {
            key: 'EnqueuedUrls',
            label: 'Urls eingereiht',
            sortable: true
          },
          /* {
            key: 'HitCount',
            label: 'Gefundene Treffer',
            sortable: true
          }, */
          {
            key: 'StartedAt',
            label: 'Gestartet am',
            sortable: true
          },
          {
            key: 'LastCrawled',
            label: 'Zuletzt eingelesen',
            sortable: true
          },
          {
            key: 'CreatedAt',
            label: 'Angelegt am',
            sortable: true
          }
        ]
      },
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Index',
          label: 'Index',
          sortable: true
        },
        {
          key: 'Priority',
          label: 'Priorität',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'Website',
          label: 'Website',
          sortable: true
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'Extractor',
          label: 'Extractor',
          sortable: true
        },
        {
          key: 'Active',
          label: 'Aktiv'
        },
        {
          key: 'Remark',
          label: 'Anmerkungen',
          sortable: true
        },
        {
          key: 'LastCrawled',
          label: 'Zuletzt eingelesen',
          sortable: true
        },
        {
          key: 'CreatedAt',
          label: 'Angelegt am',
          sortable: true
        }
      ]
    }
  },
  mounted () {
  },
  beforeUnmount () {
    clearInterval(this.refreshInterval)
  },
  deactivated () {
    clearInterval(this.refreshInterval)
  },
  methods: {
    deleteEnqueued (item) {
      axios.delete(`crawler/api/enqueued/${item.Domain.Id}`)
        .then(() => {
          mutations.setSuccess('Job erfolgreich gelöscht', '')
          this.$refs['table-2'].refresh()
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    deleteRunning (item) {
      axios.post(`crawler/api/crawler/${item.Index}/stop`)
        .then(() => {
          mutations.setSuccess('Job erfolgreich gestopp', '')
          this.$refs['table-1'].refresh()
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    toggleRefresh: function () {
      if (this.refresh === 'refresh') {
        this.refreshInterval = setInterval(() => {
          this.$refs['table-1'].refresh()
          this.$refs['table-2'].refresh()
        }, 2000)
      } else {
        clearInterval(this.refreshInterval)
      }
    },
    items (ctx, callback) {
      let q = 'crawler/api/enqueued'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      axios.get(q)
        .then(res => {
          let items
          if (res.data.Jobs === null || res.data.Jobs === undefined) {
            this.rowsFiltered = 0
            this.rowsTotal = 0
            items = []
          } else {
            items = res.data.Jobs
            this.rowsFiltered = items.length
            this.rowsTotal = res.data.Total
          }
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    workerItems (ctx, callback) {
      let q = 'crawler/api/fetcher/status'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      axios.get(q)
        .then(res => {
          const items = []
          this.workers.rowsFiltered = res.data.length
          res.data.map(v => {
            if (v.CurrentDomain.Id !== 0) {
              v._showDetails = true
              items.push(v)
            }
          })
          // this.domainSet.rowsTotal = res.data.Count
          /* items.forEach(item => {
            item._showDetails = true
          }) */
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    }
    /* rowClicked (row) {
      this.$router.push('/crawler/results/' + row.Id)
    }, */
  }
}
</script>
<!-- lang="scss" -->
<style scoped>

  .immorahmen {
    width: 1200px;
    height: 900px;
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .description {
    background-color:white;
    padding:1rem;
    border-radius: 4px;
    border: 1px solid #707070;
    opacity: 1;
    margin-bottom: 1rem;
    width: 40%;
  }

  h1 {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 32px!important;
    padding: 1rem;
  }
</style>



import Vue from 'vue'
import axios from 'axios'
import DomainForm from './Form.vue'
import { mutations } from '@/main'

export default Vue.extend({
  components: { DomainForm },
  name: 'DomainEdit',
  data: function () {
    return {
      Website: '',
      EntryPoint: '',
      Name: '',
      Extractor: 'Ibl',
      Status: '',
      UpdatedAt: '',
      Active: false,
      Remark: '',
      Tags: [],
      CustomIp: '',
      UseJs: true,
      UrlWhitelists: [],
      loaded: false,
      ExpectedResults: 0,
      MaxCrawlDuration: 0,
      AdjacentDomains: {
        Next: 0,
        Previous: 0
      },
      CustomCrawler: '',
      domain: {},
      crawlStatistics: {
        rawData: {},
        searchOptions: {
          dateRange: {
            startDate: new Date(2018, 1, 1).toISOString().split('T')[0],
            endDate: new Date(Date.now()).toISOString().split('T')[0]
          },

          search: '',
          currentPage: 1,
          sortBy: '',
          sortDesc: false,
          itemsPerPage: 50
        },
        fields: [
          {
            key: 'Id',
            label: 'Id',
            sortable: true
          },
          {
            key: 'Extractor',
            label: 'Extraktor',
            sortable: true
          },
          {
            key: 'UseJs',
            label: 'Javascript',
            sortable: true
          },
          {
            key: 'Expected',
            label: 'Erwartete Treffer',
            sortable: true
          },
          {
            key: 'PagesCrawled',
            label: 'Pages',
            sortable: true
          },
          {
            key: 'Hits',
            label: 'Treffer',
            sortable: true
          },
          {
            key: 'HitRatio',
            label: 'Trefferqoute',
            sortable: false
          },
          {
            key: 'Validated',
            label: 'Gültige Treffer',
            sortable: true
          },
          {
            key: 'StartedAt',
            label: 'Gestartet um',
            sortable: true
          },
          {
            key: 'FinishedAt',
            label: 'Abgeschlossen um',
            sortable: true
          },
          {
            key: 'FinishedStatus',
            label: 'Status',
            sortable: true
          }
        ],
        items: []
      },
      rowsTotal: 0,
      rowsFiltered: 0,
      perPageOptions: [10, 25, 50, 100]
    }
  },
  updated () {
    // this.update()
  },
  mounted () {
    this.update()
  },
  watch: {
    $route (to, from) {
      this.update()
    }
  },
  methods: {
    update () {
      this.loaded = false
      const id = this.$route.params.id
      axios.get(`crawler/api/domains/${id}`)
        .then(result => {
          this.domain = result.data
          this.loaded = true
          console.log(result.data)
        })
        .catch(error => {
          mutations.setAlert(error)
        })
      axios.get(`crawler/api/domains/${id}/adjacent_domains`)
        .then(result => {
          this.AdjacentDomains = result.data
        })
    },
    next: function () {
      if (this.AdjacentDomains.Next && this.AdjacentDomains.Next > 0) {
        this.$router.push(`/crawler/domains/${this.AdjacentDomains.Next}`)
      }
    },
    previous: function () {
      if (this.AdjacentDomains.Previous && this.AdjacentDomains.Previous > 0) {
        this.$router.push(`/crawler/domains/${this.AdjacentDomains.Previous}`)
      }
    },
    saveNext: function (ev: any) {
      if (this.AdjacentDomains.Next && this.AdjacentDomains.Next > 0) {
        this.save(ev, `/crawler/domains/${this.AdjacentDomains.Next}`)
      } else {
        this.save(ev, '')
      }
    },
    save: function (ev: any, redirect: string) {
      const id = this.$route.params.id
      const domain = ev
      domain.ExpectedResults = parseInt(ev.ExpectedResults)
      domain.MaxCrawlDuration = parseInt(ev.MaxCrawlDuration)
      axios.post('crawler/api/domains/' + id, domain)
        .then((resp) => {
          if (redirect !== '') {
            this.$router.push(redirect)
            this.update()
          } else {
            this.$nextTick(() => {
              this.$router.push('/crawler/domains/' + resp.data)
            })
          }
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    crawlerItems (ctx: any, callback: any) {
      console.log('loading crawler items')
      let q = 'crawler/api/analytics/crawl_statistics'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      q += '&order_by=' + ctx.sortBy
      q += '&order_desc=' + ctx.sortDesc
      q += '&domain=' + ctx.filter
      // q += '&time_from=' + this.searchOptions.dateRange.startDate
      // q += '&time_to=' + this.searchOptions.dateRange.endDate

      axios.get(q)
        .then(res => {
          let items
          if (res.data === null || res.data === undefined) {
            this.rowsFiltered = 0
            this.rowsTotal = 0
            this.crawlStatistics.rawData = {}
            items = []
          } else {
            items = res.data.Values
            this.crawlStatistics.rawData = res.data
            this.rowsFiltered = res.data.Filtered
            this.rowsTotal = res.data.Total
          }
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
    }
  }
})

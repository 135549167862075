<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <user-statistics>
        </user-statistics>
      </div>
      <div class="col">
        <seller-profile-statistics></seller-profile-statistics>
      </div>
    </div>
  </div>
</template>

<script>
import UserStatistics from '../../components/immopark/statistics/UserStatistics'
import SellerProfileStatistics from '../../components/immopark/statistics/SellerProfileStatistics'
export default {
  name: 'Statistics',
  components: { SellerProfileStatistics, UserStatistics }
}
</script>

<style scoped>

</style>

export default {
  data () {
    return {
      pagination: {
        perPageOptions: [50, 100, 200, 500],
        currentPage: 1,
        perPage: 50,
        rows: 0
      }
    }
  }
}

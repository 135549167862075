import Vue from 'vue'
import Router from 'vue-router'

// default pages
import Login from '@/views/Login.vue'
import Services from '@/views/immopark/Services.vue'
import OiImport from '@/views/immopark/OiImport.vue'
import Statistics from '@/views/immopark/Statistics.vue'
import Dashboard from '@/views/immopark/Dashboard.vue'

// seller_profile pages
import SellerProfiles from '@/views/immopark/seller_profiles/SellerProfiles.vue'
import SellerProfile from '@/views/immopark/seller_profiles/SellerProfile.vue'
import CountSellerProfiles from '@/views/immopark/seller_profiles/CountSellerProfiles.vue'
import SellerProfilesMessages from '@/views/immopark/seller_profiles/SellerProfilesMessages.vue'
import SellerProfilesActivities from '@/views/immopark/seller_profiles/SellerProfilesActivities.vue'
import SellerProfilesFtp from '@/views/immopark/seller_profiles/SellerProfilesFTP.vue'

// user pages
import UserProfiles from '@/views/immopark/users/UserProfiles.vue'
import UserProfilePage from '@/views/immopark/users/EditUser.vue'
import UsersCountBy from '@/views/immopark/users/CountUsers.vue'

// real_estate pages
import RealEstates from '@/views/immopark/real_estates/RealEstates.vue'
import RealEstate from '@/views/immopark/real_estates/RealEstate.vue'

// crawler default pages
import CrawlerHome from '@/views/crawler/Home.vue'
import CrawlerResults from '@/views/crawler/Results.vue'

// crawler domain pages
import CrawlerDomains from '@/views/crawler/domain/Domains.vue'
import CrawlerDomainEdit from '@/views/crawler/domain/Edit.vue'
import CrawlerDomainNew from '@/views/crawler/domain/New.vue'
import CrawlerPending from '@/views/crawler/scraper/Pending.vue'

// crawler reports
import Reports from '@/views/crawler/reports/Reports.vue'

import DomainStatistic from '@/views/crawler/statistics/Statistics.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: { name: 'Dashboard' }
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/services',
      name: 'Services',
      component: Services
    },
    {
      path: '/oi_import',
      name: 'Oi-Importer',
      component: OiImport
    },
    {
      path: '/statistics',
      name: 'Statistics',
      component: Statistics
    },
    // seller_profile routes
    {
      path: '/seller_profiles',
      name: 'SellerProfiles',
      component: SellerProfiles
    },
    {
      path: '/seller_profiles/count_by',
      name: 'SellerProfilesCountBy',
      component: CountSellerProfiles
    },
    {
      path: '/seller_profiles/all_messages',
      name: 'SellerProfilesAllMessages',
      component: SellerProfilesMessages
    },
    {
      path: '/seller_profiles/ftps',
      name: 'SellerProfilesFtp',
      component: SellerProfilesFtp
    },
    {
      path: '/seller_profiles/activities',
      name: 'SellerProfilesActivities',
      component: SellerProfilesActivities
    },
    {
      path: '/seller_profiles/:id',
      name: 'SellerProfile',
      component: SellerProfile
    },
    // user routes
    {
      path: '/users',
      name: 'UserProfiles',
      component: UserProfiles
    },
    {
      path: '/users/new',
      name: 'UserProfileNew',
      component: UserProfilePage,
      props: route => ({ sp_id: route.query.sp_id })
    },
    {
      path: '/users/count_by',
      name: 'UsersCountBy',
      component: UsersCountBy
    },
    {
      path: '/users/:id',
      name: 'UserProfileEdit',
      component: UserProfilePage
    },
    // real_estate routes
    {
      path: '/real_estates',
      name: 'RealEstates',
      component: RealEstates
    },
    {
      path: '/real_estates/:id',
      name: 'RealEstate',
      component: RealEstate
    },
    // crawler routes
    {
      path: '/crawler',
      name: 'CrawlerHome',
      component: CrawlerHome
    },
    {
      path: '/crawler/results/:id',
      name: 'CrawlerResults',
      component: CrawlerResults
    },
    {
      path: '/crawler/domains',
      name: 'CrawlerDomains',
      component: CrawlerDomains
    },
    {
      path: '/crawler/domains/:id',
      name: 'CrawlerDomainEdit',
      component: CrawlerDomainEdit
    },
    {
      path: '/crawler/domain/new',
      name: 'CrawlerDomainNew',
      component: CrawlerDomainNew
    },
    {
      path: '/crawler/reports',
      name: 'Reports',
      component: Reports
    },
    {
      path: '/crawler/pending',
      name: 'CrawlerPending',
      component: CrawlerPending
    },
    {
      path: '/crawler/statistics',
      name: 'DomainStatistic',
      component: DomainStatistic
    }
  ]
})

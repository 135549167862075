<template>
  <b-container class="w-100 mw-100">
    <div class="row mb-2">
      <div class="col-12 col-lg-8">
        <advanced-search v-model="search" disable-id-search></advanced-search>
      </div>
      <div class="col-12 col-lg-4">
        <per-page-select v-model="pagination"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table id="imports-pending-table" :items="items" :fields="fields" :filter="search" :per-page="pagination.perPage" :current-page="pagination.currentPage"
                 empty-text="Keine ausstehenden Importe" show-empty striped responsive="sm" no-provider-sorting no-provider-filtering>
          <template v-slot:cell(SellerProfileName)="data">
            <a :href="'https://immopark.de/makler/' + data.item.SellerProfileId"
               target="_blank">{{ data.item.SellerProfile ? data.item.SellerProfile.Name : 'kein Anbieterprofil verfügbar' }}</a>
          </template>
          <template v-slot:cell(SellerProfileId)="data">
            <router-link :to="'seller_profiles/'+ data.item.SellerProfileId">{{ data.item.SellerProfileId }}</router-link>
          </template>
          <template v-slot:cell(Date)="data">
            {{ new Date(data.item.Date).toLocaleString() }}
          </template>
          <template v-slot:cell(ImportStopped)="data">
          <b-button :variant="importToggleSettings(data.item).variant"
                    v-b-tooltip.hover.left="importToggleSettings(data.item).tooltip"
                    size="sm" block
                    :disabled = "!importToggleSettings(data.item).enabled"
                    @click="toogleImport(data.item.SellerProfile)"
          >
            {{ data.item.ImportStopped ? 'Gestoppt' : 'Läuft' }}
          </b-button>
          <!--<b-tooltip :target="'importer-btn-'+data.item.Id" placement="">
            {{ data.item.ImportStopped ? 'Import starten' : 'Import stoppen' }}
          </b-tooltip>-->
        </template>
        </b-table>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="totalRows"
          :per-page="pagination.perPage"
          aria-controls="imports-pending-table"
          align="right"
          size="sm"
        ></b-pagination>
      </div>
    </div>
  </b-container>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'Oi-Import-Pending',
  components: { PerPageSelect, AdvancedSearch },
  mixins: [
    PaginationMixin
  ],
  data () {
    return {
      search: '',
      fields: [
        {
          key: 'SellerProfileId',
          label: 'Anbieter Id',
          sortable: true
        },
        {
          key: 'SellerProfileName',
          label: 'Anbieter',
          sortable: true
        },
        {
          key: 'FileName',
          label: 'Datei',
          sortable: true
        },
        {
          key: 'Date',
          label: 'Erstellt am',
          sortable: true
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true
        }
      ],
      items: [
      ]
    }
  },
  computed: {
    totalRows () {
      return this.items ? this.items.length : 0
    }
  },
  methods: {
    toogleImport: function (item) {
      const q = process.env.VUE_APP_API_PATH + '/seller_profiles/' + item.Id +
        (item.ImportStopped ? '/enable_import' : '/disable_import')
      axios.get(q)
        .then(() => {
          mutations.setSuccess(
            item.ImportStopped
              ? 'Import erfolgreich gestartet für ' + item.Name
              : 'Import erfolreich gestoppt für ' + item.Name
          )
          item.ImportStopped = !item.ImportStopped
        })
        .catch(error => {
          console.log(error)
          mutations.setAlert(error)
        })
    },
    getImports: function () {
      const q = process.env.VUE_APP_API_PATH + '/import_jobs'
      axios.get(q)
        .then(response => {
          this.items = response.data
        })
        .catch(error => {
          console.log(error)
          mutations.setAlert(error)
        })
    },
    importToggleSettings (item) {
      if (item.SellerProfile === null) {
        return {
          variant: 'danger',
          tooltip: 'Fehler: das Maklerprofil existiert nicht',
          status: 'Fehler',
          enabled: false
        }
      }
      if (item.SellerProfile.ImportStopped) {
        return {
          variant: 'danger',
          tooltip: 'Import starten',
          status: 'Gestoppt',
          enabled: true
        }
      } else {
        return {
          variant: 'success',
          tooltip: 'Import stoppen',
          status: 'Läuft',
          enabled: true
        }
      }
    }
  },
  mounted () {
    this.updateInterval = setInterval(this.getImports, 5000)
  },
  destroyed () {
    clearInterval(this.updateInterval)
  }
}
</script>

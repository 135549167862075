<template>
<div class="card">
  <div class="card-header">Nutzerstatistiken</div>
  <div class="card-body">
    <div class="row">
      <div class="col-4">
        120000 Nutzer online
      </div>
      <div class="col-8">
        <user-chart :chart-data="userData" :options="userOptions"></user-chart>
        <h4 align="right">MUSTER</h4>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import UserChart from '../../charts/UserChart'
export default {
  name: 'UserStatistics',
  components: {
    'user-chart': UserChart
  },
  data () {
    return {
      userData: null,
      userOptions: null
    }
  },
  methods: {
    fillData () {
      this.userData = {
        labels: [
          'Januar', 'Februar', 'März', 'April',
          'Mai', 'Juni', 'Juli', 'August',
          'September', 'Oktober', 'November', 'Dezember'
        ],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#0ef808',
            data: [240, 255, 349, 610, 780, 910, 1400, 1900, 2300, 3512, 4581, 4300]
          }
        ]
      }
    }
  },
  mounted () {
    this.fillData()
  }
}
</script>

<style scoped>

</style>

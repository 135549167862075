<template>
  <ul>
    <li v-if="item.Street !== ''">{{ item.Street + ' ' + item.HouseNumber }}</li>
    <li v-if="item.Zip || item.City">{{ item.City ? item.Zip + ' ' + item.City : 'PLZ ' + item.Zip }}</li>
    <li v-if="item.Country">{{ item.Country }}</li>
    <li>
      <a :href="'https://www.google.de/maps/search/' + [item.Street, item.HouseNumber, item.Zip, item.City, item.Country].join(' ')"
          target="_blank" v-b-tooltip="'Auf Google Maps suchen'"
          v-if="[item.Street, item.HouseNumber, item.Zip, item.City, item.Country].join(' ').length > 5">
          <b-icon-geo-alt class="mr-1"></b-icon-geo-alt>Auf Google suchen
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'address-column',
  props: {
    item: {}
  },
  methods: {
  }
}
</script>

<style scoped>
  ul {
    margin: 0;
  }
  ul li {
    margin: 0;
    display: block;
  }
</style>

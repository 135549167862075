<template>
  <div class="row">
    <div v-if="!disableIdSearch" class="col-12 col-lg-3 mr-0 pr-0">
      <b-input-group
        id="search-id-group"
        label-for="search-id-input"
        description="nach id suchen"
        size="sm"
        class=""
      >
        <b-form-input
          id="sp-search-by-id-input"
          v-model="byId"
          type="number"
          min="0"
          placeholder="nach id suchen"
          class=""
          :debounce="debounce"
        >
        </b-form-input>
      </b-input-group>
    </div>
    <div :class="!disableIdSearch ? 'col-12 col-lg-9 pl-0 ml-0' : 'col-12'">
      <b-input-group
        id="search-any-group"
        label-for="search-any-input"
        description="nach beliebigen Feldern suchen"
        size="sm"
        class=""
      >
        <b-form-input
          id="sp-search-input"
          v-model="byAny"
          type="text"
          placeholder="nach beliebigen Feldern suchen"
          class=""
          :debounce="debounce"
        >
        </b-form-input>
        <b-input-group-append>
          <b-button @click="clearSearch" :disabled="searchEmpty">Filter entfernen</b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'advanced-search',
  props: {
    disableIdSearch: Boolean
  },
  data () {
    return {
      byId: '',
      byAny: '',
      search: '',
      debounce: 500
    }
  },
  watch: {
    byId: function () {
      if (this.disableIdSearch) {
        return
      }
      if (this.searchEmpty) {
        this.search = ''
        return
      }
      if (this.byId) {
        this.byAny = ''
        this.search = 'id:' + this.byId
      }
    },
    byAny: function () {
      if (this.searchEmpty) {
        this.search = ''
        return
      }
      if (this.byAny) {
        this.byId = ''
        this.search = this.byAny
      }
    },
    search: function () {
      this.$emit('input', this.search)
    }
  },
  computed: {
    searchEmpty () {
      return !this.disableIdSearch
        ? this.byId === '' && this.byAny === ''
        : this.byAny === ''
    }
  },
  methods: {
    clearSearch () {
      this.byId = ''
      this.byAny = ''
      this.search = ''
    }
  }
}
</script>

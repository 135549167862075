<template>
  <div>
    <div class="row mb-3">
      <div class="col-12 col-lg-8">
        <advanced-search v-model="search"></advanced-search>
      </div>
      <div class="col-12 col-lg-4">
        <per-page-select v-model="pagination"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-table ref="importDoneTable"
                 :items="items" :fields="fields"
                 striped hover selectable
                 :sort-by="sortBy"
                 :sort-desc="sortDesc"
                 :filter="search"
                 select-mode="single"
                 :current-page="pagination.currentPage" :per-page="pagination.perPage"
                 @row-clicked="expandRow">
          <template v-slot:cell(SellerProfile)="data">
            <router-link v-if="data.item.SellerProfile" :to="'/seller_profiles/' + data.item.SellerProfile.Id">{{ data.item.SellerProfile.Name }}</router-link>
            <span v-else><em>Kein Makler</em></span>
          </template>
          <template v-slot:cell(SellerProfileId)="data">
            {{ data.item.SellerProfile ? data.item.SellerProfile.Id : 'null' }}
          </template>
          <template v-slot:cell(CreatedAt)="data">
            {{ new Date(data.item.CreatedAt).toLocaleString() }}
          </template>
          <template v-slot:cell(LoadedEstates)="data">
            <ul v-if="data.item.ImportDetails">
              <li v-if="data.item.ImportDetails.filter(d => d.ImportStatus === 'New').length">
                <strong>Neu: </strong> {{ data.item.ImportDetails.filter(d => d.ImportStatus === 'New').length }}
              </li>
              <li v-if="data.item.ImportDetails.filter(d => d.ImportStatus === 'Updated').length">
                <strong>Aktualisiert: </strong> {{ data.item.ImportDetails.filter(d => d.ImportStatus === 'Updated').length }}
              </li>
              <li v-if="data.item.ImportDetails.filter(d => d.ImportStatus === 'Deleted').length">
                <strong>Gelöscht: </strong> {{ data.item.ImportDetails.filter(d => d.ImportStatus === 'Deleted').length }}
              </li>
              <li v-if="data.item.ImportDetails.filter(d => d.ImportStatus === 'Failed').length">
                <strong>Fehlgeschlagen: </strong> {{ data.item.ImportDetails.filter(d => d.ImportStatus === 'Failed').length }}
              </li>
            </ul>
          </template>
          <template v-slot:row-details="row">
            <b-table :items="row.item.ImportDetails" :fields="importDetailsFields">
              <template v-slot:cell(RealEstateId)="data">
                <router-link v-if="data.item.RealEstateId>0" :to="'/real_estates/' + data.item.RealEstateId">{{ data.item.RealEstateId }}</router-link>
              </template>
              <template v-slot:cell(Title)="data">
                <a v-if="data.item.RealEstateId > 0" :href="'https://immopark.de/real_estates/' + data.item.RealEstateId"
                   target="_blank">{{ data.item.Title }}</a>
              </template>
              <template v-slot:cell(ImportStatus)="data">
                <b-badge v-if="data.item.ImportStatus==='Deleted'" variant="dark">Gelöscht</b-badge>
                <b-badge v-else-if="data.item.ImportStatus==='Failed'" variant="bg-danger">Fehlgeschlagen</b-badge>
                <b-badge v-else-if="data.item.ImportStatus==='New'" variant="success">Neu</b-badge>
                <b-badge v-else-if="data.item.ImportStatus==='Updated'" variant="info">Aktualisiert</b-badge>
              </template>
            </b-table>
          </template>
        </b-table>
      </div>
      <div class="col-12 mt-2">
        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="rowsFiltered"
          :per-page="pagination.perPage"
          aria-controls="importDoneTable"
          align="right"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import SearchMixin from '@/components/SearchMixin'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'oi-import-finished',
  components: { AdvancedSearch, PerPageSelect },
  mixins: [
    PaginationMixin,
    SearchMixin
  ],
  data () {
    return {
      rowsFiltered: 0,
      sortBy: 'CreatedAt',
      sortDesc: true,
      fields: [
        {
          key: 'Id',
          label: '#',
          sortable: true
        },
        {
          key: 'SellerProfile',
          label: 'Anbieter',
          sortable: true
        },
        {
          key: 'SellerProfileId',
          label: 'Anbieter Id',
          sortable: true
        },
        {
          key: 'FileName',
          label: 'Datei',
          sortable: true
        },
        {
          key: 'ImportType',
          label: 'Import Typ',
          sortable: true
        },
        {
          key: 'LoadedEstates',
          label: 'Geladen',
          sortable: false
        },
        {
          key: 'CreatedAt',
          label: 'Erstellt am',
          sortable: true
        }
      ],
      importDetailsFields: [
        {
          key: 'RealEstateId',
          label: 'Immobilien Id',
          sortable: true
        },
        {
          key: 'ProviderIdentifier',
          label: 'Anbieter Id',
          sortable: true
        },
        {
          key: 'Title',
          label: 'Titel',
          sortable: true
        },
        {
          key: 'ImportStatus',
          label: 'Status',
          sortable: true
        }
      ]
    }
  },
  methods: {
    expandRow (row) {
      row._showDetails = !row._showDetails
    },
    items (ctx, callback) {
      let q = '/imports'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&filter=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDesc
      axios.get(q)
        .then(res => {
          const items = res.data.data
          this.rowsFiltered = res.data.recordsFiltered
          this.rowsTotal = res.data.recordsTotal
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error, 'danger')
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    }
  },
  mounted () {
  }
}
</script>

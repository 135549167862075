<template>
  <div class="card">
    <div class="card-header">
      Domains
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 mb-2">
          <router-link class="float-right" :to="{ name: 'CrawlerDomainNew' }">
            <h6><b-icon-plus scale="1.5" class="mr-1"></b-icon-plus>Neue Domain anlegen</h6>
          </router-link>
        </div>
        <div class="col-12 col-md-8 mb-2">
          <b-input-group
            id="domain-search-group"
            label-for="domain-search-input"
            description="Nach Domains suchen"
            size="sm"
            class=""
          >
            <b-form-input
              id="domain-search-input"
              v-model="searchSettings.filter"
              type="text"
              placeholder="nach beliebigen Feldern suchen"
              class=""
              debounce="500"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button @click="searchSettings.filter = ''" :disabled="searchSettings.filter === ''">Filter entfernen</b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
        <div class="col-12 col-md-4">
          <b-form-group
            label="Pro Seite"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align="right"
            label-align-sm="right"
            label-size="sm"
            label-for="perPageSelect"
            class="mb-0"
          >
            <b-form-select
              v-model="searchSettings.itemsPerPage"
              id="perPageSelect"
              size="sm"
              :options="perPageOptions"
            >
            </b-form-select>
          </b-form-group>
        </div>
        <div class="col-12 overflow-auto">
          <b-table id="table" ref="table"
                   :fields="fields" :items="items"
                   :per-page="searchSettings.itemsPerPage" :current-page="searchSettings.currentPage"
                   :filter="searchSettings.filter" @row-clicked="rowClicked"
                   :sort-by="searchSettings.sortBy"
                   :sort-desc="searchSettings.sortDesc"
                   striped hover selectable select-mode="single"
                   empty-filtered-text="Keine Ergebnisse"
                   empty-text="Keine Ergebnisse"
                   show-empty
                   show-empty-filtered>
            <template v-slot:cell(Id)="data">
              {{ data.item.Id }}
            </template>
            <template v-slot:cell(Name)="data">
              <strong>{{ data.item.Name }}</strong><br>
              <a :href="data.item.Website" target="_blank">{{ data.item.Website }}</a>
            </template>
            <template v-slot:cell(Status)="data">

              <b-icon-exclamation-triangle
                v-if="data.item.Status.toLowerCase().includes('timeout exceeded') ||
                data.item.Status.toLowerCase().includes('failed to detect language')"
                variant="warning"
                font-scale="1.5"
                class="font-weight-bold"
                v-b-tooltip="data.item.Status">
              </b-icon-exclamation-triangle>

              <b-icon-exclamation-triangle
                v-else-if="data.item.Status.toLowerCase().includes('failed to allow domain') ||
                data.item.Status.toLowerCase().includes('normalization error') ||
                data.item.Status.toLowerCase().includes('failed to add domain website')"
                variant="danger"
                font-scale="1.5"
                class="font-weight-bold"
                v-b-tooltip="data.item.Status">
              </b-icon-exclamation-triangle>

              <b-icon-gear
                v-else-if="data.item.Status.toLowerCase().includes('in progress')"
                variant="primary"
                font-scale="1.5"
                class="font-weight-bolder"
                v-b-tooltip="data.item.Status">
              </b-icon-gear>

              <b-icon-check-circle v-else variant="success" font-scale="1.5"
                                   v-b-tooltip="data.item.Status"
                                   class="font-weight-bolder">
              </b-icon-check-circle>
            </template>
            <template v-slot:cell(ValidCount)="data">
              <div v-if="data.item.ExpectedResults > 0">
                <span :class="deviatonClass(data.item) + ' resultSpan'" :style="deviationStyle(data.item)">
                    {{
                    data.item.ValidCount }}/{{ data.item.ExpectedResults
                  }}
                  ({{
                    (((data.item.ValidCount - data.item.ExpectedResults) / data.item.ExpectedResults) * 100).toFixed(0)
                  }}%)
                </span>
              </div>
              <span v-else :class="'resultSpan'" >{{ data.item.ValidCount }}</span>
              <router-link :to="('/crawler/results/' + data.item.Id)"><b-icon-eye/></router-link>
            </template>
            <template v-slot:cell(VTRatio)="data">
              {{ (data.item.VTRatio * 100).toFixed(2) }}%
            </template>
            <template v-slot:cell(Extractor)="data">
              <div v-if="!data.item.CustomCrawler">
                <strong>{{ data.item.Extractor }}</strong>
              </div>
              <div v-else>
                <strong>{{ customCrawlerOpts(data.item.CustomCrawler) }}</strong>
              </div>
            </template>
            <template v-slot:cell(Active)="data">
              <b-form-checkbox
                switch
                :id="'checkbox-' + data.item.Id"
                v-model="data.item.Active"
                @change="updateDomain(data.item)"
              >
              </b-form-checkbox>
            </template>
            <template v-slot:cell(UseJs)="data">
              <b-icon-check variant="success" v-if="data.item.UseJs" scale="1.5">
              </b-icon-check>
              <b-icon-x variant="danger" scale="1.5" v-else></b-icon-x>
            </template>
            <template v-slot:cell(WhitelistCount)="data">
                {{ data.item.WhitelistCount }} {{ data.item.WhitelistCount==='1' ? 'Einträge' : 'Eintrag' }}
            </template>
            <template v-slot:cell(LastCrawled)="data">
              {{ new Date(data.item.LastCrawled).toLocaleString() }}
            </template>
            <template v-slot:cell(UpdatedAt)="data">
              {{ new Date(data.item.UpdatedAt).toLocaleString() }}
            </template>
            <template v-slot:row-details="row">
              <div class="row">
                <div class="col-12 col-md-4">
                  <b-input-group>
                    <b-form-input :id="'remark-' + row.item.Id" v-model="row.item.Remark" debounce="500"></b-form-input>
                    <b-input-group-append>
                      <b-button size="sm" variant="success" @click="updateDomain(row.item)">
                        <b-icon-arrow-right-square-fill></b-icon-arrow-right-square-fill>
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                </div>
                <div class="col-12 col-md-4">
                  <h5><span class="badge badge-primary m-1" v-for="r in row.item.Tags" v-bind:key="'tag_'+ r.Id">
                    {{r.Value}}
                  </span>
                  </h5>
                </div>
                <div class="col-12 col-md-4">
                  <router-link :to="'/crawler/domains/'+row.item.Id" custom v-slot="{ navigate }" class="float-right">
                    <b-button @click="navigate" title="Bearbeiten" variant="success" size="sm" squared><b-icon-pencil-square></b-icon-pencil-square></b-button>
                  </router-link>
                  <b-button
                    @click="restartCrawler(row.item.Id)"
                    squared size="sm" variant="success"
                    v-b-tooltip.hover title="Neu Crawlen"
                    class="float-right">
                    <b-icon-download/>
                  </b-button>
                  <b-button @click="deleteItem(row.item)" variant="danger" size="sm" squared class="float-right"><b-icon-x></b-icon-x></b-button>
                </div>
              </div>
            </template>
          </b-table>
        </div>
        <div class="col-12">
          <b-pagination
            class="d-flex"
            v-model="searchSettings.currentPage"
            :total-rows="rowsTotal"
            :per-page="searchSettings.itemsPerPage"
            align="right">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'

export default {
  name: 'Domains',
  components: { },
  data: function () {
    return {
      data: {
        // Records: []
      },
      customCrawlerOptions: [],
      searchSettings: {
        filter: '',
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: 'Name',
        sortDesc: false
      },
      fetcherRunning: false,
      rowsTotal: 0,
      perPageOptions: [10, 25, 50, 100, 500],
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'NormalizedWebsite',
          label: 'Normalisierte Website',
          sortable: true
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true
        },
        /* {
          key: 'TotalCount',
          label: 'T',
          sortable: true
        }, */
        {
          key: 'ValidCount',
          label: 'V',
          sortable: true
        },
        {
          key: 'InvalidCount',
          label: 'IV',
          sortable: true
        },
        {
          key: 'VTRatio',
          label: 'V/T',
          sortable: true
        },
        {
          key: 'ReportsCount',
          label: 'Fehlermeldungen',
          sortable: true
        },
        {
          key: 'Extractor',
          label: 'Extraktor',
          sortable: true
        },
        {
          key: 'Active',
          label: 'Aktiv',
          sortable: true
        },
        {
          key: 'UseJs',
          label: 'JS',
          sortable: true
        },
        {
          key: 'Language',
          label: 'Sprache',
          sortable: true
        },
        {
          key: 'WhitelistCount',
          label: 'Whitelist',
          sortable: true
        },
        {
          key: 'LastCrawled',
          label: 'Zuletzt eingelesen',
          sortable: true
        },
        {
          key: 'UpdatedAt',
          label: 'Zuletzt bearbeitet',
          sortable: true
        }
      ]
    }
  },
  watch: {
    searchSettings: {
      handler (val) {
        mutations.setPreferences('domains-search-settings', val)
      },
      deep: true
    }
  },
  computed: {
  },
  mounted () {
    const p = mutations.getPreferences('domains-search-settings')
    if (p == null) {
      this.searchSettings = {
        filter: '',
        currentPage: 1,
        itemsPerPage: 25,
        sortBy: 'Name',
        sortDesc: false
      }
    } else {
      this.searchSettings = p
    }
    axios.get('crawler/api/custom_crawler/options')
      .then(r => {
        this.customCrawlerOptions = r.data
      })
  },
  methods: {
    customCrawlerOpts (key) {
      const opt = this.customCrawlerOptions.find(o => o.value === key)
      return opt ? opt.text : ''
    },
    newTemplate: function (id) {
      this.$router.push(`/crawler/extractor/new/${id}`)
    },
    items (ctx, callback) {
      this.searchSettings.sortBy = ctx.sortBy
      this.searchSettings.sortDesc = ctx.sortDesc
      this.searchSettings.currentPage = ctx.currentPage
      this.searchSettings.itemsPerPage = ctx.perPage
      let q = 'crawler/api/domains'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&filter=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDescd
      axios.get(q)
        .then(res => {
          const items = res.data.Records
          if (items === null) {
            this.rowsTotal = 0
            // eslint-disable-next-line standard/no-callback-literal
            callback([])
            return
          }
          // this.rowsFiltered = res.data.RecordsFiltered
          this.rowsTotal = res.data.RecordsFiltered
          items.forEach(item => {
            item._showDetails = true
          })
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    rowClicked (row) {
      console.log('row clicked')
      this.$router.push('/crawler/domains/' + row.Id)
    },
    showResults (domainId, event) {
      if (event) event.preventDefault()
      this.$router.push('/crawler/results/' + domainId)
    },
    restartCrawler: function (id) {
      this.$bvModal.msgBoxConfirm('Domain neu crawlen?',
        {
          title: 'Bestätigen',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          centered: true
        }
      )
        .then(value => {
          if (value) {
            axios.get(`crawler/api/domains/${id}/crawl`)
              .then(r => {
                mutations.setSuccess('Domain wird neu geladen')
              })
              .catch(err => {
                mutations.setAlert(err)
              })
          }
        })
    },
    deleteItem (item) {
      axios.delete('/crawler/api/domains/' + item.Id)
        .then(() => {
          mutations.setSuccess('Domain erfolgreich gelöscht', '')
          this.$refs.table.refresh()
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    updateDomain (item) {
      axios.post('/crawler/api/domains/' + item.Id, item)
        .then(() => {
          mutations.setSuccess('domain updated')
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    deviatonClass (item) {
      const devAbs = Math.abs((item.ValidCount - item.ExpectedResults) / item.ExpectedResults)
      if (devAbs < 0.1) {
        return 'deviation deviaton-low'
      } else if (devAbs < 0.3) {
        return 'deviation deviaton-medium'
      } else {
        return 'deviation deviaton-high'
      }
    },
    deviationStyle (item) {
      const devAbs = Math.abs((item.ValidCount - item.ExpectedResults) / item.ExpectedResults)
      if (devAbs < 0.1) {
        return 'color: 00a500;'
      } else if (devAbs < 0.3) {
        return 'color: gold;'
      } else {
        return 'color: red'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  span.deviation{
    &.deviation-low {
      color: #00a500;
    }
    &.deviation-medium {
      color: gold;
    }
    &.deviation-high {
      color: red;
    }
  }

  .resultSpan {
    display:flex;
    width: 100%;

    &:hover {
      color: #00A500;
    }
  }

  .immorahmen {
    width: 1200px;
    height: 900px;
    margin-top: 26px;
    margin-bottom: 26px;
  }

  .description {
    background-color:white;
    padding:1rem;
    border-radius: 4px;
    border: 1px solid #707070;
    opacity: 1;
    margin-bottom: 1rem;
    width: 40%;
  }

  h1 {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 32px!important;
    padding: 1rem;
  }
</style>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          Navigation
        </div>
        <div class="card-body">
          <div v-if="navigationMode==='immopark'">
            <b-navbar-nav>
              <b-nav-item to="/services">
                <b-icon-server></b-icon-server>Dienste
              </b-nav-item>
              <b-nav-item :to="{ name: 'Oi-Importer' }">
                <b-icon-cloud-upload-fill></b-icon-cloud-upload-fill>Importer
              </b-nav-item>
              <b-nav-item :to="{ name: 'SellerProfilesFtp'}">
                <b-icon-list-ul></b-icon-list-ul>FTP-Zugänge
              </b-nav-item>
              <b-nav-item :to="{ name: 'SellerProfiles'}">
                <b-icon-list-ul></b-icon-list-ul>Makler Profile
              </b-nav-item>
              <b-nav-item :to="{ name: 'SellerProfilesCountBy'}">
                <b-icon-bar-chart-line-fill></b-icon-bar-chart-line-fill>Makler pro Region
              </b-nav-item>
              <b-nav-item :to="{ name: 'SellerProfilesAllMessages'}">
                <b-icon-chat-left-text-fill></b-icon-chat-left-text-fill>Nachrichten
              </b-nav-item>
              <b-nav-item :to="{ name: 'SellerProfilesActivities'}">
                <b-icon-handbag-fill></b-icon-handbag-fill>Aktivitäten
              </b-nav-item>
              <b-nav-item :to="{ name: 'UserProfiles'}">
                <b-icon-list-ul></b-icon-list-ul>Nutzerprofile
              </b-nav-item>
              <b-nav-item :to="{ name: 'UsersCountBy'}">
                <b-icon-bar-chart-line-fill></b-icon-bar-chart-line-fill>Nutzer aufzählen
              </b-nav-item>
              <b-nav-item :to="{ name: 'RealEstates' }">
                <b-icon-house-fill></b-icon-house-fill>Immobilien
              </b-nav-item>
              <b-nav-item :to="{ name: 'Statistics' }">
                <b-icon-graph-up></b-icon-graph-up>Statistiken
              </b-nav-item>
              <!--<b-nav-item class="nav-link ml-auto" :to="{ name: 'CrawlerHome' }" right>Startseite</b-nav-item>
              <b-nav-item class="nav-link ml-auto" :to="{ name: 'CrawlerDomains' }" right>Domains</b-nav-item>
              <b-nav-item class="nav-link ml-auto" :to="{ name: 'CrawlerExtractor' }" right>Extractor</b-nav-item>-->
            </b-navbar-nav>
          </div>
          <div v-if="navigationMode==='crawler'">
            <b-navbar-nav>
              <b-nav-item :to="{ name: 'CrawlerHome' }">
                <b-icon-cloud-upload-fill></b-icon-cloud-upload-fill>Startseite
              </b-nav-item>
              <b-nav-item :to="{ name: 'CrawlerDomains' }">
                <b-icon-cloud-upload-fill></b-icon-cloud-upload-fill>Domains
              </b-nav-item>
              <b-nav-item :to="{ name: 'CrawlerPending' }">
                <b-icon-list-ol></b-icon-list-ol>Ausstehende Domains
              </b-nav-item>
              <b-nav-item :to="{ name: 'Reports' }">
                <b-icon-file-text-fill></b-icon-file-text-fill>Fehlermeldungen
              </b-nav-item>
              <b-nav-item :to="{name: 'DomainStatistic'}">
                <b-icon-bar-chart-steps variant="info"></b-icon-bar-chart-steps>Domain Statistiken
              </b-nav-item>
            </b-navbar-nav>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import { store } from '@/main'

export default {
  name: 'Sidebar',
  data () {
    return {
      expanded: true
    }
  },
  computed: {
    navigationMode () {
      return store.mode
    }
  }
}
</script>

<style scoped>
  .b-icon {
    margin-right: 1rem;
    width: 2rem;
    height: 1.5rem;
  }

  a.nav-link {
    border-bottom-color: #00A500;
    border-bottom-style: solid;
    border-bottom-width: 1px;
  }
</style>

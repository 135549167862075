<template>
    <div class="card">
      <div class="card-header">
        Domain Anlegen
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <domain-form button-label="Anlegen" @save-button="create" :show-next-buttons="false"
                         :initial-use-js="true"
                         :initial-max-crawl-duration="MaxCrawlDuration"
                         :domain="domain"
            >
            </domain-form>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import DomainForm from '@/views/crawler/domain/Form'
import axios from 'axios'
import { mutations } from '@/main'

export default {
  components: { DomainForm },
  name: 'DomainNew',
  data: function () {
    return {
      Website: '',
      Name: '',
      Extractor: 'Ibl',
      ExpectedResults: 0,
      MaxCrawlDuration: 86400000000000,
      domain: {}
    }
  },
  methods: {
    create: function (ev) {
      console.log('save new', ev)
      const domain = ev

      domain.ExpectedResults = parseInt(ev.ExpectedResults)
      domain.MaxCrawlDuration = parseInt(ev.MaxCrawlDuration)

      axios.post('/crawler/api/domains', ev)
        .then((res) => {
          this.$router.push({ path: `/crawler/api/domains/${res.data}` })
          this.$nextTick(() => {
            mutations.setSuccess('Domain angelegt.')
          })
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    }
  }
}
</script>

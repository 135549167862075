<template>
    <div class="row">
      <div class="col-12 title-row bg-white">
        Mapachitl Dashboard
      </div>
      <div class="col-12 bg-white pb-3 pt-3">
        <div class="mt-4 shadow">
          <div class="card-header">
            Links zur Statistiken
          </div>
          <div class="card-body">
            <div id="section">
              <ul>
                <li>
                  <a href="https://mapachitl.grafana.godmp.de/d/CRUC59lnk/tagsverteilung?orgId=1" target="_blank"
                  class="btn btn-primary">Die Statistiken der Tagsverteilung</a>
                </li>
                <li>
                  <a href="https://mapachitl.grafana.godmp.de/d/zK-D0o_nk/domainubersicht?orgId=1" target="_blank"
                  class="btn btn-secondary">Die Statistiken des Domainübersichts</a>
                </li>
                <li>
                  <a href="https://mapachitl.grafana.godmp.de/d/ip7JJo_7k/immobilien-nach-extraktoren?orgId=1" target="_blank"
                  class="btn btn-success">Immobilien nach Extraktoren</a>
                </li>
                <li>
                  <a href="https://mapachitl.grafana.godmp.de/d/48qLAT_7z/quellen?orgId=1" target="_blank"
                  class="btn btn-dark">Die Statistiken von Quellen</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <br/>
        <b-tabs>
          <b-tab title="Alle">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <div class="card mt-4 shadow">
                      <div class="card-header">
                        Übersicht
                      </div>
                      <div class="card-body">
                        <ul class="vertical">
                          <li>Registrierte Domains: {{ dashboardData.Domains }}</li>
                          <li>Neue Domains: {{ dashboardData.NewDomains }}</li>
                          <li>Gemeldete Domains: {{ dashboardData.ReportedDomains}}</li>
                          <li>Templates: {{ dashboardData.Templates }}</li>
                          <li>Ergebnisse: {{ dashboardData.Results }} Immobilien</li>
                          <li>Gültig: {{ dashboardData.Valid }} Immobilien</li>
                          <li>Ungültig: {{ dashboardData.Invalid }} Immobilien</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="card mt-4 shadow">
                      <div class="card-header">
                        <h5>Domains nach Feldern</h5>
                      </div>
                      <div class="card-body">
                        <b-table-lite :fields="fields.byExtractor" :items="dashboardData.ByExtractor">
                        </b-table-lite>
                        <hr>
                        <b-table-lite :fields="fields.byActive" :items="dashboardData.ByActive">
                          <template v-slot:cell(Field)="data">
                            {{ data.item.Field === 't' ? 'Aktiv' : 'Inaktiv' }}
                          </template>
                        </b-table-lite>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="card mt-4 shadow">
                      <div class="card-header">
                        <h6>Domains mit mindestens <b>N</b> gültigen Treffern</h6>
                      </div>
                      <div class="card-body">
                        <b-table-lite :fields="fields.byNValid" :items="minNValid">
                          <template v-slot:cell(n)="data">
                            > {{ data.item.n }}
                          </template>
                        </b-table-lite>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card mt-4 shadow">
                      <div class="card-header">
                        Wordcloud
                      </div>
                      <div class="card-body">
                        <word-cloud></word-cloud>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="card mt-4 shadow">
                      <div class="card-header">
                        JSON-Data
                      </div>
                      <div class="card-body">
                        <json-view :data="dashboardData" class="result-json"></json-view>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="KI Statistiken (ALPHA)">
            <ai-statistics></ai-statistics>
          </b-tab>
        </b-tabs>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import moment from 'moment'

export default {
  name: 'Home',
  components: {
  },
  data () {
    return {
      updateInterval: null,

      dashboardData: {
        Domains: 0,
        ActiveDomains: 0,
        NewDomains: 0,
        Templates: 0,
        ReportedDomains: 0,
        Results: 0,
        Valid: 0,
        Invalid: 0,
        MinNValid: [],
        Tags: []
      },
      fields: {
        byExtractor: [
          {
            key: 'Field',
            label: 'Extractor'
          },
          {
            key: 'Count',
            label: 'Domains'
          }
        ],
        byActive: [
          {
            key: 'Field',
            label: 'Aktiv'
          },
          {
            key: 'Count',
            label: 'Domains'
          }
        ],
        byNValid: [
          {
            key: 'n',
            label: 'N'
          },
          {
            key: 'c',
            label: 'Domains'
          }
        ],
        byStatus: [
          {
            key: 'Field',
            label: 'Status'
          },
          {
            key: 'Count',
            label: 'Domains'
          }
        ]
      },
      crawlerWorkers: [],
      crawlerWorkersFields: [
        {
          key: 'WorkerStatus',
          label: 'Status',
          sortable: true
        },
        {
          key: 'StartedAt',
          label: 'Gestartet am',
          sortable: true
        },
        {
          key: 'Domain',
          label: 'Domain',
          sortable: true
        },
        {
          key: 'DomainExtractor',
          label: 'Extraktor',
          sortable: true
        },
        {
          key: 'DomainStatus',
          label: 'Domain Status',
          sortable: true
        },
        {
          key: 'DomainLastCrawled',
          label: 'Zuletzt gecrawled',
          sortable: true
        },
        {
          key: 'DomainsCrawled',
          label: 'Domains gecrawlt',
          sortable: true
        }
      ]
    }
  },
  methods: {
    update () {
      axios.get('crawler/api/dashboard')
        .then((resp) => {
          this.dashboardData = resp.data
        })
        .catch((err) => {
          console.log(err)
          mutations.setAlert(err)
        })

      axios.get('crawler/api/fetcher/status')
        .then((resp) => {
          this.crawlerWorkers = resp.data
        })
        .catch((err) => {
          console.log(err)
          mutations.setAlert(err)
        })
    },
    calcToNow (date) {
      if (moment(date).unix() <= -62000000000) {
        return ''
      }
      return moment(date).fromNow()
    }
  },
  computed: {
    minNValid () {
      const arr = []
      Object.entries(this.dashboardData.MinNValid).forEach(([key, value]) => {
        arr.push({
          n: key,
          c: value
        })
      })
      return arr
    },
    grafanaUrl () {
      return process.env.VUE_APP_GRAFANA_URL
    }
  },
  mounted () {
    this.updateInterval = setInterval(this.update(), 1000)
  },
  beforeUnmount () {
    clearInterval(this.updateInterval)
  }
}
</script>
<!--lang="scss" -->
<style scoped>

  .background {
    display:flex;
    justify-content: center;
    align-items: center;
    background-image: url(/assets/img/search_bgn_grey.jpg);
  }
  h1 {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 32px!important;
    padding: 1rem;
    text-align: center;
  }

  .untertitel {
    padding: 1.5rem;
  }

  .rahmen {
    background: #D3E6A5 0% 0% no-repeat padding-box;
    border: 1px solid #2B4D1A;
    opacity: 1;
    padding:1rem;
    max-width: 333px;
    min-height: 490px;;
    text-align: center;
  }

  .vertical li {
    display: block;
  }

  .result-json {
    width: auto;
    height: auto;
    background-color: inherit;
    overflow: auto;
    max-height: 400px;
  }
</style>

<template>
  <div v-if="estate" class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6 mt-4">
        <div class="card h-100">
          <div class="card-header">Immobiliendaten</div>
          <div class="card-body">
            <b-form>
              <div class="row">
                <div class="col-12">
                    <b-form-group
                      id="headline-group"
                      label-for="headline-input"
                      description="Titelzeile"
                      class="w-100"
                    >
                      <b-form-input class="form-control" id="headline-input" v-model="estate.Headline"></b-form-input>
                    </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group
                    id="provider-identifier-group"
                    label-for="provider-identifier-input"
                    description="Provider Identifier"
                    class="w-100"
                  >
                    <b-form-input class="form-control" id="provider-identifier-input" v-model="estate.ProviderIdentifier"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12">
                  <b-form-group
                    id="feedback-email-group"
                    label-for="feedback-email-input"
                    description="Feedback Email"
                    class="w-100"
                  >
                    <b-form-input class="form-control" id="feedback-email-input" v-model="estate.EmailFeedback"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-12 col-md-6">
                  <b-form-select
                        id="status-select"
                        v-model="estate.CurrentStatus" :options="statusOptions">
                      </b-form-select>
                </div>
                <div class="col-12 col-md-6">
                  <b-button class="ip_btn h-100 w-100"
                            variant="primary" squared
                            @click="saveChanges"
                  >
                    <b-icon-file-earmark-arrow-up-fill></b-icon-file-earmark-arrow-up-fill>Speichern</b-button>
                </div>
              </div>
            </b-form>
          </div>
          <div class="card-footer">
            <strong>Erstellt am </strong> {{ new Date(estate.CreatedAt).toLocaleString() }}
            <strong>Zuletzt bearbeitet am </strong> {{ new Date(estate.UpdatedAt).toLocaleString() }}
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6 mt-4">
        <contact-card :contact="estate.Contact" class="h-100"></contact-card>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <div class="card">
          <div class="card-header">Bilder</div>
          <div class="card-body">
            <div class="row m-0">
              <div class="col-12">
                <vueper-slides class="no-shadow mx-3"
                               :visible-slides="4"
                               slide-multiple
                               :gap="2"
                               :slide-ratio="1/4"
                               :dragging-distance="70"
                               bullets-outside
                               transition-speed="250"
                               arrows-outside>
                  <vueper-slide v-for="(item, i) in estate.Pictures"
                                :key="i"
                                :title="item.Title"
                                :image="item.Formats.small || 'https://immopark.de/assets/default_avatar/default_avatar.png'"
                                class="slide">
                  </vueper-slide>
                </vueper-slides>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12">
        <real-estate-features :features="estate.Features"></real-estate-features>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import RealEstateFeatures from '@/views/immopark/real_estates/RealEstateFeatures'
import ContactCard from '@/components/contacts/ContactCard'
import { mutations } from '@/main'
import { VueperSlide, VueperSlides } from 'vueperslides'

export default {
  name: 'real-estate',
  components: {
    ContactCard,
    RealEstateFeatures,
    VueperSlide,
    VueperSlides
  },
  props: {
  },
  data () {
    return {
      search: '',
      estate: null,
      realEstateId: 0,
      filtered: [],
      itemsPerPage: 20,
      currentPage: 1,
      totalRows: 0,
      recordsTotal: 0,
      recordsFiltered: 0,
      pagedPictures: {},
      statusOptions: [
        { value: null, text: 'Wählen Sie einen Status' },
        { value: 'deleted', text: 'Gelöscht' },
        { value: 'visible', text: 'Sichtbar' },
        { value: 'hidden', text: 'Versteckt' },
        { value: 'sold', text: 'Verkauft' }
      ]
    }
  },
  watch: {
    /* 'sellerProfileId': {
      handler: function (val, oldVal) {
        this.requestSellerProfileEstates()
      },
      deep: true
    } */
  },
  methods: {
    saveChanges () {
      alert('Nicht implementiert!')
      /* console.log('saving changes to estate')
      console.log(this.estate)
      let q = '/real_estates/' + this.$route.params.id
      axios.post(q, this.estate)
        .then(() => {
          console.log('POST')
          console.log(this.estate)

          mutations.setSuccess('Immobilie erfolgreich aktualisiert')
        })
        .catch(error => {
          console.log(error)
          mutations.setAlert(error)
        }) */
    },
    requestRealEstate: function () {
      const q = '/real_estates/' + this.realEstateId
      const config = {
        params: {
          fields: 'contact,features,pictures'
        }
      }
      axios.get(q, config)
        .then(response => {
          this.estate = response.data
        })
        .catch((error) => {
          mutations.setAlert(error)
        })
    },
    onChange: function (page) {
      this.currentPage = page
      this.requestRealEstate()
    }
  },
  created () {
    this.realEstateId = this.$route.params.id
  },
  mounted () {
    this.requestRealEstate()
  }
}
</script>

<style scoped>
  .slide {
    border-color: #eeeeee;
    border-style: solid;
  }
</style>

<template>
  <div class="container-fluid" id="seller-profile">
    <div class="row">
      <div class="col-12 mt-4">
        <div class="card">
          <div class="card-header">Maklerdaten</div>
          <div class="card-body">
            <div class="row">
              <div class="col-3 mb-4">
                <div class="row align-self-stretch">
                  <div class="col-12 mb-4">
                    <h5>Firmenlogo</h5>
                    <b-img :src="sellerProfile.CompanyLogo || 'https://immopark.de/assets/default_avatar/default_avatar.png'"
                           alt="Firmenlogo"
                           class="border shadow" fluid center/>
                  </div>
                  <div class="col-12">
                    <div class="data-field" v-if="sellerProfile.FtpPasswd">
                      <p :class="ftpExpanded ? 'mb-2' : 'mb-0'">FTP-Zugang
                        <b-button variant="link" size="sm" class="m-0 p-0" @click="ftpExpanded = !ftpExpanded">
                          <b-icon-dash v-if="ftpExpanded"></b-icon-dash>
                          <b-icon-plus v-else></b-icon-plus>
                        </b-button>
                      </p>
                      <b-collapse v-model="ftpExpanded">
                        <ul class="ellipsis">
                          <li><strong>Firmenname:</strong>{{ sellerProfile.Name }}</li>
                          <li><strong>Server: </strong>upload.immopark.de</li>
                          <li><strong>Port: </strong>21</li>
                          <li><strong>Benutzername: </strong>{{ sellerProfile.Id }}</li>
                          <li><strong>Passwort: </strong>{{ sellerProfile.FtpPasswd }}</li>
                          <li><strong>Link: </strong>
                            <a :href="['ftp://', sellerProfile.Id, ':', sellerProfile.FtpPasswd, '@upload.immopark.de/'].join('')"
                               target="_blank">
                              {{ ['ftp://', sellerProfile.Id, ':', sellerProfile.FtpPasswd, '@upload.immopark.de/'].join('') }}
                            </a>
                          </li>
                        </ul>
                      </b-collapse>
                    </div>
                    <div class="data-field" v-else>
                      <p class="mb-0">Kein Ftp-Zugang vorhanden
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-9 mb-4">
                <b-form>
                  <h5>Firmenanschrift</h5>
                  <div class="row">
                    <div class ="col-12">
                      <b-form-group id="sp-name-group" label="" label-for="sp-name-input" description="Firmenname" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-name-input" v-model="sellerProfile.Name" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group id="sp-street-group" label="" label-for="sp-street-input" description="Straße" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-street-input" v-model="sellerProfile.Street" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group id="sp-housenumber-group" label="" label-for="sp-housenumber-input" description="Hausnummer" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-housenumber-input" v-model="sellerProfile.HouseNumber" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group id="sp-zip-group" label="" label-for="sp-zip-input" description="PLZ" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-zip-input" v-model="sellerProfile.Zip" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group id="sp-city-group" label="" label-for="sp-city-input" description="Stadt" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-city-input" v-model="sellerProfile.City" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                    </div>
                    <div class="col-6">
                      <b-form-group id="sp-country-group" label="" label-for="sp-country-input" description="Land" size="sm" class="mb-4">
                        <b-form-input class="form-control" id="sp-country-input" v-model="sellerProfile.Country" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group id="sp-phone-group" label="" label-for="sp-phone-input" description="Telefon" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-phone-input" v-model="sellerProfile.Phone" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-6">
                      <b-form-group id="sp-email-group" label="" label-for="sp-email-input" description="E-Mail" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-email-input" v-model="sellerProfile.EmailDirect" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <b-form-group id="sp-homepage-group" label="" label-for="sp-homepage-input" description="Homepage" size="sm" class="mb-0">
                        <b-form-input class="form-control" id="sp-homepage-input" v-model="sellerProfile.Homepage" size="sm"></b-form-input>
                      </b-form-group>
                    </div>
                    <div class="col-12">
                      <b-form-group id="sp-imprint-group" label="" label-for="sp-imprint-input" description="Impressum" size="sm" class="mb-0">
                        <b-form-textarea
                          id="sp-imprint-input"
                          v-model="sellerProfile.Imprint"
                          rows="6"
                          max-rows="6"
                        ></b-form-textarea>
                      </b-form-group>
                    </div>
                    <div class="col-12 text-right mr-0">
                      <b-button @click="reload" squared class="ip_btn ip_btn-danger" variant="danger"><b-icon-arrow-repeat class="mr-1"></b-icon-arrow-repeat>Änderungen verwerfen</b-button>
                      <b-button @click="save" squared class="ip_btn"><b-icon-cloud-upload-fill class="mr-1"></b-icon-cloud-upload-fill>Speichern</b-button>
                    </div>
                  </div>
                </b-form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-4">
        <seller-profile-contacts :sellerProfileId="sellerProfile.Id" :owner="sellerProfile.UserId" :contacts="sellerProfile.Contacts"></seller-profile-contacts>
      </div>
      <div class="col-12 mt-4">
        <sp-estates :seller-profile-id="$route.params.id"/>
      </div>
      <div class="col-12 mt-4">
        <sp-messages :seller-profile-id="$route.params.id" class="my-2"></sp-messages>
      </div>
    </div>
  </div>
</template>

<script>

import SellerProfileContacts from './SellerProfileContacts'
import axios from 'axios'
import { mutations } from '@/main'
export default {
  name: 'seller-profile',
  components: {
    SellerProfileContacts
  },
  data () {
    return {
      sellerProfile: { },
      editImprint: false,
      newImprint: '',
      ftpExpanded: false
    }
  },
  computed: {
    sellerProfileStreet: function () {
      return [this.sellerProfile.Street, this.sellerProfile.HouseNumber].join(' ')
    },
    sellerProfileCity: function () {
      return [this.sellerProfile.Zip, this.sellerProfile.City].join(' ')
    }
  },
  methods: {
    updateImprint () {
      this.sellerProfile.imprint = this.newImprint
    },
    cancelUpdateImprint () {
      this.newImprint = ''
    },
    getAddress: (item) => {
      const addr = {}

      if (item.Street !== '') {
        addr.street = item.Street
      }
      if (item.HouseNumber && item.HouseNumber !== '') {
        addr.street += ' ' + item.HouseNumber
      }
      if (item.Zip !== '') {
        addr.city = item.Zip
        if (item.City !== '') {
          addr.city += ' ' + item.City
        }
      } else {
        if (item.City !== '') {
          addr.city += item.City
        }
      }
      if (item.Country !== '') {
        addr.country = item.Country
      }
      return addr
    },
    reload () {
      const config = {
        params: {
          fields: 'contacts'
        }
      }
      axios.get('/seller_profiles/' + this.$route.params.id, config)
        .then(response => {
          this.sellerProfile = response.data
        })
        .catch(error => {
          this.mutations.setAlert(error)
        })
    },
    save () {
      this.$awn.confirm(
        'Möchten Sie die Änderungen endgültig speichern?',
        () => {
          axios.post('/seller_profiles/' + this.$route.params.id,
            this.sellerProfile)
            .then(() => {
              mutations.setSuccess('Maklerprofile erfolgreich aktualisiert')
            })
            .catch(error => {
              this.mutations.setAlert(error)
            })
            .finally(this.refresh())
        },
        null,
        {
          labels: {
            confirm: 'Sind Sie sicher?',
            ok: 'Ja',
            cancel: 'Nein'
          }
        })
    }
  },
  beforeMount () {
    this.reload()
  }
}
</script>

<style scoped>
  ul {
    margin: 0;
    display: block;
  }
  li {
    margin: 0;
    display: block;
  }
</style>

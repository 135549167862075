<template>
  <div class="card">
    <div class="card-header">
      Nachrichten
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 text-right mb-2">
          <router-link v-if="linkPage" to="/seller_profiles/all_messages">alle Nachrichten anzeigen</router-link>
        </div>
        <div class="col-12">
          <b-table-lite :fields="fields" :items="[stats]">
          </b-table-lite>
        </div>
        <div class="col-12">
          <h5>Letzte Anfragen</h5>
          <span class="m-4" v-for="(item, index) in receiverItems" :key="index">{{ item }}<br></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'messages-stats',
  props: {
    linkPage: Boolean
  },
  data () {
    return {
      stats: {},
      receivers: [],
      fields: [
        {
          key: 'Today',
          label: 'Heute'
        },
        {
          key: 'Yesterday',
          label: 'Gestern'
        },
        {
          key: 'LastWeek',
          label: 'In der letzten Woche'
        },
        {
          key: 'LastMonth',
          label: 'Im letzten Monat'
        },
        {
          key: 'LastYear',
          label: 'Im letzten Jahr'
        },
        {
          key: 'Total',
          label: 'Gesamt'
        }
      ]
    }
  },
  computed: {
    receiverItems () {
      const rItems = []
      const max = 5
      for (let i = 0; i < max && i < this.receivers.length; i++) {
        rItems.push(this.receivers[i])
      }
      if (this.receivers.length > max) {
        rItems.push('Und ' + (this.receivers.length - max) + ' weitere ...')
      }
      return rItems
    }
  },
  watch: {
  },
  methods: {
    getStats: function () {
      axios.get('/seller_profiles/message_stats')
        .then(response => {
          this.stats = response.data.Messages
          this.receivers = response.data.Receivers
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
        })
    }
  },
  mounted () {
    this.getStats()
  }
}
</script>

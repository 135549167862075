import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@voerro/vue-tagsinput/dist/style.css'
import { BootstrapVue, IconsPlugin, DropdownPlugin, PopoverPlugin } from 'bootstrap-vue'
import '@/assets/css/style.scss'

// @ts-ignore
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import VueI18n, { TranslateResult } from 'vue-i18n'
import { languages, defaultLocale } from '@/assets/locales/index'
import { NavigationGuardNext, Route } from 'vue-router'

// @ts-ignore
import VoerroTagsInput from '@voerro/vue-tagsinput'

Vue.component('tags-input', VoerroTagsInput)
Vue.config.productionTip = false

const axios = require('axios')

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(DropdownPlugin)
Vue.use(PopoverPlugin)
Vue.use(VueI18n)

const messages = Object.assign(languages)
const i18n = new VueI18n({
  locale: defaultLocale,
  messages: messages
})

export default axios

axios.defaults.baseURL = process.env.VUE_APP_API_PATH

/**
 * Store for session token and other global stuff.
 *
 * @type {{sessionToken: string}} the session Token
 */
export const store = Vue.observable({
  mode: '',
  sessionToken: '',
  showMenu: false,
  alert: {
    show: false,
    message: '',
    variant: 'danger',
    showDetails: true,
    details: ''
  },
  success: {
    show: false,
    message: '',
    showDetails: true,
    details: ''
  }
})

/**
 * Mutation handler for the store items
 *
 * @type {{setSessionToken(*=): void, logout(): void}}
 */
export const mutations = {
  setNavigationMode (mode: string) {
    store.mode = mode
  },
  setLoggedInUser (user: any) {
    // Put the object into storage
    localStorage.setItem('immoparkDashboardUser', JSON.stringify(user))
    localStorage.setItem('immoparkDashboardToken', user.Token)
    store.showMenu = true
  },
  getLoggedInUser () {
    const u = localStorage.getItem('immoparkDashboardUser')
    if (u != null) {
      return JSON.parse(u)
    }
    return null
  },
  getPreferences (key: string) {
    const p = localStorage.getItem(key)
    if (p != null) {
      return JSON.parse(p)
    }
    return null
  },
  setPreferences (key: string, preferences: object) {
    localStorage.setItem(key, JSON.stringify(preferences))
  },
  setSessionToken (token: string) {
    localStorage.setItem('immoparkDashboardToken', token)
    store.showMenu = true
  },
  getSessionToken () {
    store.showMenu = !!localStorage.getItem('immoparkDashboardToken')
    return localStorage.getItem('immoparkDashboardToken')
  },
  logout () {
    axios.defaults.headers.common.Authorization = 'Bearer Nope'
    localStorage.removeItem('immoparkDashboardUser')
    localStorage.removeItem('immoparkDashboardToken')
    store.showMenu = false
    VueInstance.$router.push('Login')
  },
  setAlert (error: any) {
    if (error === undefined || error === null) {
      console.log('no error')
      store.alert.show = false
      store.alert.message = 'Ein unbekannter Fehler ist aufgetreten'
      store.alert.variant = 'primary'
      store.alert.showDetails = false
      store.alert.details = ''
      return
    }

    if (typeof error === 'string' || typeof error === 'number') {
      console.log('primitive error')
      store.alert.variant = 'danger'
      store.alert.message = error.toString()
      store.alert.showDetails = false
      store.alert.details = ''
      store.alert.show = true
      return
    }

    if (error.response !== undefined) {
      console.log('error with response')
      if (error.response.status < 300) {
        store.alert.variant = 'primary'
      } else if (error.response.status < 400) {
        store.alert.variant = 'warning'
      } else {
        store.alert.variant = 'danger'
      }
      if (error.response.data.Message) {
        store.alert.message = error.response.data.Message
      } else {
        store.alert.message = error.response.status + ' - ' + error.response.statusText
      }
      store.alert.showDetails = true
      store.alert.details = error.config.url
      store.alert.show = true
    } else {
      console.log('other error')
      store.alert.show = true
      store.alert.message = error
      store.alert.show = true
    }
  },
  unsetAlert () {
    store.alert.show = false
  },
  setSuccess (message: string | TranslateResult, details: string | TranslateResult = '') {
    if (!message) {
      store.success.show = false
      store.success.message = ''
      store.success.showDetails = false
      store.success.details = ''
    }
    store.success.message = message as string
    store.success.details = details as string
    store.success.show = true
    store.success.showDetails = true
  },
  unsetSuccess () {
    store.success.show = false
  }
}

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  config.headers.common.Authorization = 'Bearer ' + localStorage.immoparkDashboardToken
  // config.headers.common['Access-Control-Allow-Origin'] = '*'
  return config
})

axios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: any) => {
    if (error.status === 401) {
      store.sessionToken = ''
      VueInstance.$router.push('Login')
    }
    return Promise.reject(error)
  })

router.beforeEach((to: Route, from: Route, next: NavigationGuardNext) => {
  mutations.unsetAlert()
  mutations.unsetSuccess()

  if (to.name !== 'Login' && !mutations.getSessionToken()) {
    next({ name: 'Login' })
  }

  // eslint-disable-next-line
  let el = to.path.replace(/^\/([^\/]*).*$/, '$1')
  switch (el) {
    case 'crawler':
      mutations.setNavigationMode('crawler')
      break
    case 'immopark':
      mutations.setNavigationMode('immopark')
      break
    default:
      mutations.setNavigationMode('immopark')
      break
  }
  next()
})

/* eslint-disable no-new */
const VueInstance = new Vue({
  el: '#app',
  router,
  i18n,
  components: {
    App,
    VueperSlides,
    VueperSlide
  },
  template: '<App/>'
})

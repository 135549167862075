<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        FTP Zugänge
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-8">
            <advanced-search v-model="search"/>
          </div>
          <div class="col-12 col-md-4">
            <per-page-select v-model="pagination"/>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <b-table id="sp-table" ref='spTable' :items="items" :fields="fields" :per-page="pagination.perPage" :current-page="pagination.currentPage" :filter="search" striped>
              <template v-slot:cell(Id)="data">
                {{ data.item.Id }}
              </template>
              <template v-slot:cell(Name)="data">
                <router-link v-if="data.item.Name && data.item.Name !== ''" :to="'/seller_profiles/'+data.item.Id" class="ellipsis">
                  <b-icon-house class="mr-1"></b-icon-house><span>{{ data.item.Name }}</span>
                </router-link>
              </template>
              <template v-slot:cell(FtpPassword)="data">
                <router-link v-if="data.item.Name && data.item.Name !== ''" :to="'/seller_profiles/'+data.item.Id" class="ellipsis">
                  <b-icon-house class="mr-1"></b-icon-house><span>{{ data.item.Name }}</span>
                </router-link>
              </template>
              <template v-slot:cell(ImportStopped)="data">
                <b-button :variant="data.item.ImportStopped ? 'danger' : 'success'"
                          v-b-tooltip.hover.left="data.item.ImportStopped ? 'Import starten' : 'Import stoppen'"
                          size="sm" block
                  @click="toogleImport(data.item)"
                >
                  {{ data.item.ImportStopped ? 'Gestoppt' : 'Läuft' }}
                </b-button>
                <!--<b-tooltip :target="'importer-btn-'+data.item.Id" placement="">
                  {{ data.item.ImportStopped ? 'Import starten' : 'Import stoppen' }}
                </b-tooltip>-->
              </template>
              <template v-slot:cell(Server)="">
                upload.immopark.de
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="rowsFiltered"
              :per-page="pagination.perPage"
              aria-controls="sp-table"
              align="right"
              size="sm"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'seller-profiles-ftp',
  components: {
    AdvancedSearch,
    PerPageSelect
  },
  mixins: [
    PaginationMixin
  ],
  data () {
    return {
      searchById: '',
      searchByFields: '',
      search: '',
      orderBy: 'id',
      desc: false,
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Firma',
          sortable: true
        },
        {
          key: 'FtpPasswd',
          label: 'FTP Passwort',
          sortable: false
        },
        {
          key: 'Server',
          label: 'Server',
          sortable: false
        },
        {
          key: 'ImportStopped',
          label: 'Importer Status',
          sortable: true
        }
      ],
      validationErrors: [
        {
          key: 'companyName',
          label: 'Ungültiger Firmenname'
        },
        {
          key: 'imprint',
          label: 'Ungültiges Impressum'
        },
        {
          key: 'logo',
          label: 'Fehlendes Firmenlogo'
        }],
      sellerProfileId: 0,
      currentSellerProfile: {},
      rowsTotal: 0,
      rowsFiltered: 0
    }
  },
  computed: {
  },
  watch: {
    searchById () {
      if (this.searchById === '' && this.searchByFields === '') {
        this.search = ''
        return
      }
      if (this.searchById) {
        this.search = 'id:' + this.searchById
        this.searchByFields = ''
      }
    },
    searchByFields () {
      if (this.searchById === '' && this.searchByFields === '') {
        this.search = ''
        return
      }
      if (this.searchByFields) {
        this.search = this.searchByFields
        this.searchById = ''
      }
    }
    /* currentPage: function () {
      this.$refs.spTable.refresh()
    } */
  },
  methods: {
    toogleImport: function (item) {
      const q = process.env.VUE_APP_API_PATH + '/seller_profiles/' + item.Id +
        (item.ImportStopped ? '/enable_import' : '/disable_import')
      axios.get(q)
        .then(() => {
          mutations.setSuccess(
            item.ImportStopped
              ? 'Import erfolgreich gestartet für ' + item.Name
              : 'Import erfolreich gestoppt für ' + item.Name
          )
          item.ImportStopped = !item.ImportStopped
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    stopImport: function (item) {
      const q = process.env.VUE_APP_API_PATH + '/seller_profiles/' + item.Id + '/disable_import'
      axios.get(q)
        .then(() => {
          mutations.setSuccess('Import erfolreich gestoppt für ' + item.Name)
        })
        .catch(error => {
          mutations.setAlert(error)
        })
        .finally(() => this.refresh())
    },
    refresh: function () {
      this.$refs.spTable.refresh()
    },
    items (ctx, callback) {
      let q = '/seller_profiles'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&search=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDesc
      axios.get(q)
        .then(res => {
          const items = res.data.data.filter(d => d.FtpPasswd !== '')
          this.rowsFiltered = res.data.recordsFiltered
          this.rowsTotal = res.data.recordsTotal
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    deleteSellerProfile: function (item) {
      const awn = this.$awn
      awn.confirm(
        'Der Makler ' + item.Name + ' (' + item.Id + ') wird endgültig gelöscht?',
        () => {
          axios.delete('/seller_profiles/' + item.Id)
            .then((response) => {
              mutations.setSuccess('Makler ' + item.Name + ' (' + item.Id + ')' + ' erfolgreich gelöscht')
            })
            .catch((error) => {
              mutations.setAlert(error)
            })
            .finally(this.refresh())
        },
        null,
        {
          labels: {
            confirm: 'Sind Sie sicher?',
            ok: 'Ja',
            cancel: 'Nein'
          }
        })
    },
    getAddress: function (item) {
      const vals = [
        item.Street,
        item.HouseNumber,
        item.Zip,
        item.City,
        item.Country
      ]
      return Object.values(vals).join(',')
    },
    validate (sp) {
      const validation = {
        ok: true,
        errors: []
      }
      if (sp.Name.length < 3) {
        validation.ok = false
        validation.errors.push('Firmenname')
      }
      if (sp.Imprint.length < 25) {
        validation.ok = false
        validation.errors.push('Impressum')
      }
      if (sp.LogoFileName.length < 3) {
        validation.ok = false
        validation.errors.push('Logo')
      }
      return validation
    },
    validationTooltip (val) {
      let html = '<ul>'
      val.errors.forEach((e) => {
        html += '<li>' + e + '</li>'
      })
      html += '</ul>'
      return html
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  .validation-ok {
     display: block;
     color: #00a500;
   }
  .validation-failed {
    display: flex;
    font-weight: normal;
    color: #ff0000;
  }
</style>

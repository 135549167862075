<template>
  <div class="card">
    <div class="card-header">
        Analyse von Domänen
    </div>
    <div class="card-body">
      <b-tabs>
        <b-tab title="Keine neue Immobilien">
          <br>
          <div class="row">
            <div class="col-8">
              <b-input-group
                id="domain-search-group"
                label-for="adomain-search-input"
                description="Nach Domains suchen"
                size="sm"
                class=""
              >
                <b-form-input
                  id="domain-search-input"
                  v-model="staleDomainsSettings.filter"
                  type="text"
                  placeholder="nach beliebigen Feldern suchen"
                  class=""
                  debounce="500"
                >
                </b-form-input>
                  <b-input-group-append>
                    <b-button @click="staleDomainsSettings.filter = ''" :disabled="staleDomainsSettings.filter === ''">Filter entfernen</b-button>
                  </b-input-group-append>
              </b-input-group>
            </div>
            <div class="col-4">
              <b-form-group label="Älter als"
                label-cols-sm="6"
                label-cols-md="4"
                label-cols-lg="3"
                label-align="right"
                label-align-sm="right"
                label-size="sm"
                label-for="age"
                class="mb-0"
              >
                <b-form-select v-model="staleDomainsSettings.olderThan"
                  id="age"
                  size="sm"
                  :options="ageOptions"
                  @change="tableStaleDomainReload">
                </b-form-select>
              </b-form-group>
              <br>
              <div class="col-14">
                <b-form-group label="Pro Seite"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align="right"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select v-model="staleDomainsSettings.itemsPerPage"
                    id="perPageSelect"
                    size="sm"
                    :options="perPageOptions"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
              <br>
            </div>
            <div class="col-12 overflow-auto">
              <b-table id="tableStaleDomain"
                ref="tableStaleDomain"
                :fields="staleDomainFields"
                :items="staleItems"
                :per-page="staleDomainsSettings.itemsPerPage"
                :current-page="staleDomainsSettings.currentPage"
                :filter="staleDomainsSettings.filter"
                :sort-by="staleDomainsSettings.sortBy"
                :sort-desc="staleDomainsSettings.sortDesc"
                striped hover selectable select-mode="single"
                empty-filtered-text="Keine Ergebnisse"
                empty-text="Keine Ergebnisse"
                @row-clicked="rowClicked"
                show-empty
                show-empty-filtered
              >
                <template v-slot:cell(Id)="data">
                  {{ data.item.Id }}
                </template>
                <template v-slot:cell(Name)="data">
                  <strong>{{ data.item.Name }}</strong><br>
                  <a :href="data.item.Website" target="_blank">{{ data.item.Website }}</a>
                </template>
                <template v-slot:cell(Status)="data">
                  {{ data.item.Status }}
                </template>
                <template v-slot:cell(ValidEstates)="data">
                  {{ data.item.ValidEstates }}
                </template>
                <template v-slot:cell(InvalidEstates)="data">
                  {{ data.item.InvalidEstates }}
                </template>
                <template v-slot:cell(ExpectedResultsCount)="data">
                  {{ data.item.ExpectedResultsCount }}
                </template>
                <template v-slot:cell(Extractor)="data">
                  <strong>{{ data.item.Extractor }}</strong>
                </template>
                <template v-slot:cell(LastFound)="data">
                  {{ new Date(data.item.LastFound).toLocaleString() }}
                </template>
                <template v-slot:cell(LastCrawled)="data">
                  {{ new Date(data.item.LastCrawled).toLocaleString() }}
                </template>
                <template v-slot:cell(CreatedAt)="data">
                  {{ new Date(data.item.CreatedAt).toLocaleString() }}
                </template>
                <template v-slot:row-details="row">
                  <div class="row">
                    <div class="col-12">
                      <h5>
                        <span class="badge badge-primary m-1" v-for="r in row.item.Tags" v-bind:key="'tag_'+ r.Id">
                                                {{r.Value}}
                        </span>
                      </h5>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="col-12">
              <b-pagination class="d-flex"
                v-model="staleDomainsSettings.currentPage"
                :total-rows="rowsTotal"
                :per-page="staleDomainsSettings.itemsPerPage"
                align="right"
              >
              </b-pagination>
            </div>
          </div>
        </b-tab>
        <b-tab title="Fehlende Adressen">
          <br>
          <div class="row">
            <div class="col-4">
              <div class="col-14">
                <b-form-group label="Pro Seite"
                  label-cols-sm="6"
                  label-cols-md="4"
                  label-cols-lg="3"
                  label-align="right"
                  label-align-sm="right"
                  label-size="sm"
                  label-for="perPageSelect"
                  class="mb-0"
                >
                  <b-form-select v-model="missingAddressSettings.itemsPerPage"
                    id="perPageSelect"
                    size="sm"
                    :options="perPageOptions"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
              <br>
            </div>
            <div class="col-12 overflow-auto">
              <b-table id="tableMissingAddress"
                ref="tableMissingAddress"
                :fields="missingAddressFields"
                :items="missingItems"
                :per-page="missingAddressSettings.itemsPerPage"
                :current-page="missingAddressSettings.currentPage"
                :sort-by="missingAddressSettings.sortBy"
                :sort-desc="missingAddressSettings.sortDesc"
                striped hover selectable select-mode="single"
                empty-filtered-text="Keine Ergebnisse"
                empty-text="Keine Ergebnisse"
                show-empty
                show-empty-filtered
              >
                <template v-slot:cell(Id)="data">
                  {{ data.item.Id }}
                </template>
                <template v-slot:cell(Name)="data">
                  <strong>{{ data.item.Name }}</strong><br>
                  <a :href="data.item.Website" target="_blank">{{ data.item.Website }}</a>
                </template>
                <template v-slot:cell(Total)="data">
                  {{ data.item.Total }}
                </template>
                <template v-slot:cell(Missing)="data">
                  {{ data.item.Missing }}
                </template>
                <template v-slot:cell(Ratio)="data">
                  {{ data.item.Ratio.toFixed(2) }} %
                </template>
              </b-table>
            </div>
            <div class="col-12">
              <b-pagination class="d-flex"
                v-model="missingAddressSettings.currentPage"
                :total-rows="rowsTotal"
                :per-page="missingAddressSettings.itemsPerPage"
                align="right"
              >
              </b-pagination>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'

export default {
  name: 'StaleDomains',
  components: { },
  data: function () {
    return {
      data: {
        // Records: []
      },
      staleDomainsSettings: {
        currentPage: 1,
        filter: '',
        itemsPerPage: 25,
        olderThan: 7,
        sortBy: 'Id',
        sortDesc: false
      },
      missingAddressSettings: {
        currentPage: 1,
        filter: '',
        itemsPerPage: 25,
        sortBy: 'Id',
        sortDesc: false
      },
      rowsTotal: 0,
      perPageOptions: [10, 25, 50, 100],
      ageOptions: [1, 7, 14, 30],
      staleDomainFields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'Status',
          label: 'Status',
          sortable: true
        },
        {
          key: 'ValidEstates',
          label: 'V',
          sortable: true
        },
        {
          key: 'InvalidEstates',
          label: 'IV',
          sortable: true
        },
        {
          key: 'ExpectedResultsCount',
          label: 'Erwartete Ergebnisse',
          sortable: true
        },
        {
          key: 'Extractor',
          label: 'Extraktor',
          sortable: true
        },
        {
          key: 'LastFound',
          label: 'Zuletzt Immobilie gefunden',
          sortable: true
        },
        {
          key: 'LastCrawled',
          label: 'Zuletzt gecrawlt',
          sortable: true
        },
        {
          key: 'CreatedAt',
          label: 'Erstellt am',
          sortable: true
        }
      ],
      missingAddressFields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'Total',
          label: 'Total',
          sortable: true
        },
        {
          key: 'Missing',
          label: 'Anzahl fehlender Adressen',
          sortable: true
        },
        {
          key: 'Ratio',
          label: 'Anteil fehlender Adressen',
          sortable: true
        }
      ]
    }
  },
  methods: {
    rowClicked (row) {
      this.$router.push('/crawler/domains/' + row.Id)
    },
    tableStaleDomainReload (event) {
      this.$refs.tableStaleDomain.refresh()
    },
    staleItems (ctx, callback) {
      this.staleDomainsSettings.sortBy = ctx.sortBy
      this.staleDomainsSettings.sortDesc = ctx.sortDesc
      this.staleDomainsSettings.currentPage = ctx.currentPage
      this.staleDomainsSettings.itemsPerPage = ctx.perPage
      let q = 'crawler/api/analytics/stale_domains'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&filter=' + ctx.filter
      }
      q += '&order_by=' + (ctx.sortBy !== '' ? ctx.sortBy : 'Id')
      q += '&desc=' + ctx.sortDesc
      q += '&older_than=' + this.staleDomainsSettings.olderThan
      axios.get(q)
        .then(res => {
          const items = res.data.Records
          if (items === null) {
            this.rowsTotal = 0
            // eslint-disable-next-line standard/no-callback-literal
            callback([])
            return
          }
          this.rowsTotal = res.data.RecordsFiltered
          items.forEach(item => {
            item._showDetails = true
          })
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      return null
    },
    missingItems (ctx, callback) {
      this.missingAddressSettings.sortBy = ctx.sortBy
      this.missingAddressSettings.sortDesc = ctx.sortDesc
      this.missingAddressSettings.currentPage = ctx.currentPage
      this.missingAddressSettings.itemsPerPage = ctx.perPage
      let q = 'crawler/api/analytics/missing_addresses'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      q += '&order_by=' + (ctx.sortBy !== '' ? ctx.sortBy : 'Id')
      q += '&desc=' + ctx.sortDesc
      axios.get(q)
        .then(res => {
          const items = res.data.Records
          console.log(q)
          if (items === null) {
            this.rowsTotal = 0
            // eslint-disable-next-line standard/no-callback-literal
            callback([])
            return
          }
          this.rowsTotal = res.data.RecordsFiltered
          items.forEach(item => {
            item._showDetails = true
          })
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      return null
    }
  }
}
</script>

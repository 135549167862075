<template>
  <div class="card">
    <div class="card-header">Maklerkontakte</div>
    <div class="card-body">
      <div class="row mb-4">
        <div class="col-12 text-right mb-2">
          <router-link :to="'/users/new?sp_id=' + this.sellerProfileId">
            <b-icon-person v-b-tooltip="'Neuen Kontakt für das Maklerprofil anlegen'"></b-icon-person>
            Neuen Kontakt anlegen
          </router-link>
        </div>
        <div class="col-12 col-lg-8">
          <advanced-search v-model="search" disable-id-search/>
        </div>
        <div class="col-12 col-lg-4">
          <per-page-select v-model="pagination"></per-page-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-table id="table" :items="contacts" :fields="contactFields" :filter="search" :current-page="pagination.currentPage" :per-page="pagination.perPage"
                   :tbody-tr-class="rowClass" striped>
            <template v-slot:cell(ContactData)="data">
              <router-link :to="'/users/' + data.item.Id">
                <b-icon-person></b-icon-person>
                {{ data.item.Salutation }} {{ data.item.Title }} {{ data.item.FirstName }} {{ data.item.LastName }}
              </router-link><br>
              <a :href="'mailto:'+data.item.Email">
                <b-icon-envelope></b-icon-envelope>
                {{ data.item.Email }}
              </a><br>
              <a v-if="data.item.PhoneExtension" :href="'tel:'+data.item.PhoneExtension">
                <b-icon-phone></b-icon-phone>
                {{ data.item.PhoneExtension }}
              </a>
              <a v-else-if="data.item.PhoneCentral" :href="'tel:'+data.item.PhoneCentral">
                <b-icon-phone></b-icon-phone>
                {{ data.item.PhoneCentral }}
              </a>
              <a v-else-if="data.item.PhonePrivate" :href="'tel:'+data.item.PhonePrivate">
                <b-icon-phone></b-icon-phone>
                {{ data.item.PhonePrivate }}
              </a>
              <a v-else-if="data.item.PhoneOther" :href="'tel:'+data.item.PhoneOther">
                <b-icon-phone></b-icon-phone>
                {{ data.item.PhoneOther }}
              </a>
            </template>
            <template v-slot:cell(Avatar)="data">
              <b-img :src="getAvatarPath(data.item)" fluid width="70em" height="70em">
              </b-img>
            </template>
            <template v-slot:cell(UserType)="data">
              {{ data.item.UserType | formatUserType }}
              <span v-if="data.item.Id === owner"><br><em>(Profilbesitzer)</em></span>
            </template>
          </b-table>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="rowsFiltered"
            :per-page="pagination.perPage"
            aria-controls="table"
            align="right"
            size="sm"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'spContacts',
  components: {
    PerPageSelect,
    AdvancedSearch
  },
  mixins: [
    PaginationMixin
  ],
  props: {
    sellerProfileId: {
      type: Number,
      default: 0
    },
    owner: {
      type: Number,
      default: 0
    },
    contacts: Array
  },
  data () {
    return {
      Id: 0,
      Name: '',
      UserId: 0,
      LogoFileName: '',
      search: '',
      rowsFiltered: 0,
      contactFields: [
        {
          key: 'Avatar',
          sortable: false
        },
        {
          key: 'ContactData',
          label: 'Kontaktdaten',
          sortable: true
        },
        {
          key: 'UserType',
          label: 'Nutzertyp',
          sortable: true
        }
      ]
    }
  },
  filters: {
    formatUserType (ut) {
      switch (ut) {
        case 'broker':
          return 'Makler'
        case 'user':
          return 'Nutzer'
        case 'anonymous':
          return 'Anonym'
        default:
          return ut
      }
    }
  },
  methods: {
    getAvatarPath (item) {
      return item.AvatarFileName
        ? process.env.VUE_APP_ATTACHMENTS_PATH + '/avatars/' + item.Id + '/default.jpg'
        : 'https://www.immopark.de/assets/default_avatar/default_avatar.png'
    },
    rowClass (item, type) {
      if (!item || type !== 'row') return
      if (item.Id === this.owner) return 'table-success'
    }
  }
}
</script>

<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">Alle Nachrichten</div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12 col-md-8">
            <advanced-search v-model="search" disable-id-search/>
          </div>
          <div class="col col-md-4 mb-2">
            <per-page-select v-model="pagination"/>
          </div>
          <div class="col-12 col-md-6">
            <label>Von</label>
            <b-form-datepicker id="datepicker-from"
                               v-model="dateRange.startDate"
                               :min="minDate"
                               :max="maxDate"
                               @input="$refs.table.refresh()"
            ></b-form-datepicker>
          </div>
          <div class="col-12 col-md-6">
            <label>Bis</label>
            <b-form-datepicker id="datepicker-to"
                               v-model="dateRange.endDate"
                               :min="dateRange.startDate"
                               :max="maxDate"
                               @input="$refs.table.refresh()"
            ></b-form-datepicker>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-table ref="table" :items="items" :fields="fields" :filter="search"
                     :current-page="pagination.currentPage" @row-clicked="expandRow"
                     :per-page="pagination.perPage" striped select-mode="single" selectable
            >
              <template v-slot:cell(CreatedAt)="data">
                {{ new Date(data.item.CreatedAt).toLocaleString() }}
              </template>
              <template v-slot:cell(Sender)="data">
                <div class="col">
                  <router-link v-if="data.item.Sender!==0" :to="'/users/' + data.item.Sender"></router-link>
                  <span v-else>{{ data.item.FirstName + ' ' + data.item.LastName }}</span>
                </div>
                <div class="col">
                  <a :href="'mailto:' + data.item.Email"><b-icon-envelope class="mr-1"></b-icon-envelope>{{ data.item.Email }}</a>
                </div>
                <div class="col">
                  <a :href="'tel:' + data.item.Phone"><b-icon-phone class="mr-1"></b-icon-phone>{{ data.item.Phone }}</a>
                </div>
              </template>
              <template v-slot:cell(Recipient)="data">
                <div class="col">
                  <router-link v-if="data.item.SellerProfileId!==0" :to="'/seller_profiles/' + data.item.SellerProfileId">
                    {{ data.item.SellerProfileName }} ({{ data.item.SellerProfileId }})
                  </router-link>
                  <span v-else>HIER SOLLTE DER EMPFÄNGER STEHEN</span>
                </div>
              </template>
              <template v-slot:cell(Subject)="data">
                <div class="col">
                  <router-link v-if="data.item.RealEstateId!==0" :to="'/real_estates/' + data.item.RealEstateId">
                    {{ data.item.RealEstateId }} {{ data.item.RealEstateHeadline ? ' - ' + data.item.RealEstateHeadline : '' }}
                  </router-link><br>
                  <b-badge variant="info">{{ data.item.Subject | formatSubject }}</b-badge>
                </div>
              </template>
              <template v-slot:cell(Message)="row">
                <div class="col">
                  {{ shortenMessage(row.item.Message, 50) }}
                </div>
              </template>
              <template v-slot:row-details="row">
                <div class="row">
                  <div class="col-12 col-md-2">
                    <img :src="getRealEstateThumbnail(row.item)" alt="Immobilie" class="image-responsive border"/>
                  </div>
                  <div class="col-12 col-md-10">
                    {{ row.item.Message }}
                  </div>
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="rowsFiltered"
              :per-page="pagination.perPage"
              aria-controls="table"
              size="sm"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'seller-profiles-all-messages',
  components: {
    PerPageSelect,
    AdvancedSearch
  },
  mixins: [PaginationMixin],
  props: {
  },
  filters: {
    formatSubject (subject) {
      switch (subject) {
        case 'appointment':
          return 'Terminvereinbarung'
        case 'information':
          return 'Information'
        case 'other':
          return 'Anderes'
        case 'buy':
          return 'Kaufen'
        case 'sell':
          return 'Verkaufen'
        case 'lease':
          return 'Pachten'
        case 'rent':
          return 'Mieten'
        default:
          return subject
      }
    }
  },
  data () {
    return {
      minDate: new Date(2016, 1, 1).toISOString().split('T')[0],
      maxDate: new Date(Date.now()).toISOString().split('T')[0],
      search: '',
      orderBy: 'id',
      desc: false,
      locale: {
        direction: 'ltr',
        format: 'dd.mm.yyyy',
        separator: ' - ',
        applyLabel: 'Anwenden',
        cancelLabel: 'Abbrechen',
        weekLabel: 'KW',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        monthNames: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        firstDay: 0
      },
      dateRange: {
        startDate: new Date(2016, 1, 1).toISOString().split('T')[0],
        endDate: new Date(Date.now()).toISOString().split('T')[0]
      },
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'CreatedAt',
          label: 'Vom',
          sortable: true
        },
        {
          key: 'Sender',
          label: 'Absender',
          sortable: true
        },
        {
          key: 'Recipient',
          label: 'Empfänger',
          sortable: false
        },
        {
          key: 'Subject',
          label: 'Betreff',
          sortable: false
        },
        {
          key: 'Message',
          label: 'Nachricht',
          sortable: true
        }
      ],
      rowsTotal: 0,
      rowsFiltered: 0
    }
  },
  methods: {
    items (ctx, callback) {
      let q = '/seller_profiles/all_messages'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&filter=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDesc
      if (this.dateRange.startDate) {
        q += '&from=' + this.dateRange.startDate
      }
      if (this.dateRange.endDate) {
        q += '&to=' + this.dateRange.endDate
      }
      axios.get(q)
        .then(res => {
          const items = res.data.data
          this.rowsFiltered = res.data.recordsFiltered
          this.rowsTotal = res.data.recordsTotal
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error, 'danger')
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    expandRow (row) {
      row._showDetails = !row._showDetails
    },
    shortenMessage: function (msg, len) {
      return msg.length > len ? msg.substring(0, len) + '...' : msg
    },
    getRealEstateThumbnail (item) {
      return process.env.VUE_APP_ATTACHMENTS_PATH + '/real_estate_picture/' + item.RealEstateId + '/resultthumb.jpg'
    }
  },
  beforeCreate () {
  },
  mounted () {
  }
}
</script>

<style scoped>
.border {
  border-width: 1px;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-4 mb-4">
        <div class="card h-100">
          <div class="card-header">
            <h3>Avatar</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <b-img class="border shadow" :src="getAvatarPath()" alt="avatar" fluid center>
                </b-img>
              </div>
              <div class="col-12">
                <b-form-file
                  v-model="avatarUpload"
                  :state="Boolean(avatarUpload)"
                  placeholder="Avatar hochladen"
                  accept="image/*"
                  class="mt-2"
                  ></b-form-file>
                <b-button @click="uploadAvatar" class="mt-2" block><b-icon-cloud-upload-fill class="mr-1"></b-icon-cloud-upload-fill>Hochladen</b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-8 mb-4">
        <div class="card h-100">
          <div class="card-header">
            Kontaktdaten
          </div>
          <div class="card-body">
            <b-form>
              <div class="row">
                <div class ="col-6">
                  <b-form-group id="salutation-group" label="Anrede" label-for="salutation-input" description="Ihre Anrede">
                    <b-form-input class="form-control" id="salutation-input" v-model="user.Salutation"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="title-group"
                    label="Titel"
                    label-for="title-input"
                    description="Titel">
                    <b-form-input class="form-control" id="title-input" v-model="user.Title"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="first-name-group"
                    label="Vorname"
                    label-for="first-name-input"
                    description="Ihr Vorname">
                    <b-form-input class="form-control" id="first-name-input" v-model="user.FirstName"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="last-name-group"
                    label="Nachname"
                    label-for="last-name-input"
                    description="Ihr Nachname"
                  >
                    <b-form-input class="form-control" id='last-name-input' v-model="user.LastName"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="street-group"
                    label="Straße"
                    label-for="street-input"
                    description="Straße">
                    <b-form-input class="form-control" id="street-input" v-model="user.Street"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group id="house-number-group" label="Hausnummer" label-for="house-number-input" description="Hausnummer">
                    <b-form-input class="form-control" id='house-number-input' v-model="user.HouseNumber"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group id="zip-group" label="PLZ" label-for="zip-input" description="Ihre Postleitzahl">
                    <b-form-input class="form-control" id='zip-input' v-model="user.Zip"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="city-group" label="Stadt" label-for="city-input" description="Ihre Stadt">
                    <b-form-input class="form-control" id='city-input' v-model="user.City"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group
                    id="phone-group" label="Telefon" label-for="phone-input" description="Ihre Telefonnummer">
                    <b-form-input class="form-control" id='phone-input' v-model="user.PhoneExtension"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group id="email-group" label="Email" label-for="email-input" description="Ihre Email-Adresse">
                    <b-form-input class="form-control" id='email-input' v-model="user.Email"></b-form-input>
                  </b-form-group>
                </div>
                <div class="col-6">
                  <b-form-group id="saved-searches-group" label="Speicherbare Suchen" label-for="saved-searches-input" description="Anzahl Suchen die ein User Speichern kann.">
                    <b-form-input class="form-control" id='saved-searches-input' type="number" v-model.number="user.AllowedSavedSearches"></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="col-12 text-right">
                <b-button @click="saveChanges" squared class="ip_btn"><b-icon-cloud-upload-fill class="mr-1"></b-icon-cloud-upload-fill>Speichern</b-button>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4 h-100">
        <div class="card h-100">
          <div class="card-header">
            Freitext
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <b-form-group id="free-text-group" label="Freitext" label-for="free-text-area" description="Freitext">
                  <b-form-textarea id="free-text-area" v-model="user.FreeText" placeholder="Freitext"/>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4 h-100">
        <div class="card h-100">
          <div class="card-header">
            Einstellungen
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <b-form-checkbox id="show-to-seller-input">Profil für Makler freigeben</b-form-checkbox>
              </div>
              <div class="col">
                <b-form-checkbox id="show-full-name-input">Vollen Namen anzeigen</b-form-checkbox>
              </div>
              <div class="col">
                <b-form-checkbox id="show-full-address-input" v-model="user.ShowAddress">Addresse anzeigen</b-form-checkbox>
              </div>
              <div class="col">
                <b-form-checkbox id="show-full-metasearch-input" v-model="user.MetaSearch">Kann Metasuchen</b-form-checkbox>
              </div>
              <div class="col">
                <b-form-checkbox id="show-poi-umspannwerke-input" v-model="user.PoiPowerStation">POI Umspannwerke</b-form-checkbox>
              </div>
            </div>
            <div class="row float-right mt-1">
              <div class="col-12">
                <b-button class="ip_btn" squared @click="saveChanges">
                  <b-icon-cloud-upload-fill class="mr-1"></b-icon-cloud-upload-fill>Speichern</b-button>
                <b-button class="ip_btn ip_btn-grey" squared @click="reloadUser">
                  <b-icon-arrow-repeat class="mr-1"></b-icon-arrow-repeat>Änderungen verwerfen
                </b-button>
                <b-button class="ip_btn ip_btn-danger" squared @click="$bvModal.show('modal-confirm-delete')">
                  <b-icon-trash-fill class="mr-1"></b-icon-trash-fill>Nutzer Löschen
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-confirm-delete"
        ref="modal"
        title="Sind Sie sicher?"
        ok-variant="danger" ok-title="Ja"
        cancel-variant="primary" cancel-title="Nein"
        @ok="onConfirmDelete">
        <p class="my-4">Sind Sie sicher, dass Sie diesen Nutzer löschen möchten?</p>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'

export default {
  name: 'user-profile-page',
  data () {
    return {
      avatarUpload: null,
      user: {
        AllowedSavedSearches: 5
      }
    }
  },
  methods: {
    reloadUser () {
      if (!this.$route.params.id) {
        return
      }
      axios.get('/users/' + this.$route.params.id)
        .then(response => {
          this.user = response.data
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    saveChanges () {
      const q = this.$route.params.id ? '/users/' + this.$route.params.id : '/users/'
      if (this.$route.query.sp_id) {
        this.user.SellerProfileId = parseInt(this.$route.query.sp_id)
      }
      axios.post(q, this.user)
        .then(() => {
          mutations.setSuccess(this.$route.params.id ? 'Nutzer erfolgreich aktualisiert' : 'Nutzer erfolgreich angelegt')
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    uploadAvatar () {
      const data = new FormData()
      data.append('uploadfile', this.avatarUpload)
      axios.post('/users/' + this.user.Id + '/avatar', data)
        .then(() => {
          mutations.setSuccess('Avatar erfolgreich hochgeladen')
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    },
    onConfirmDelete (bvModalEvent) {
      axios.delete('/users/' + this.user.Id)
        .then(() => {
          mutations.setSuccess('Nutzer ' + this.user.Id + ' gelöscht')
        })
        .catch((error) => {
          mutations.setAlert(error)
        })
    },
    getAvatarPath () {
      return this.user.AvatarFileName ? process.env.VUE_APP_ATTACHMENTS_PATH + '/avatars/' + this.user.Id + '/default.jpg'
        : 'https://www.immopark.de/assets/default_avatar/default_avatar.png'
    }
  },
  mounted () {
    this.reloadUser()
  }
}
</script>

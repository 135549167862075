<template>
  <div class="card">
    <div class="card-header">Features</div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-12 col-md-8">
          <advanced-search v-model="search" disable-id-search/>
        </div>
        <div class="col-12 col-md-4">
          <per-page-select v-model="pagination"/>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <b-table id="features-list" class="w-100 mw-100 mb-4"
                   :fields="fields" :filter="search" :items="Object.values(features)"
                   :per-page="pagination.perPage" :current-page="pagination.currentPage" sort-by="Id"
                    fixed>
            <template v-slot:cell(Value)="data">
              <p v-if="data.item.FeatureType === 'FeatureData::RichTextFeature'">
                {{ data.item.Value }}
              </p>
              <p v-else-if="data.item.FeatureType === ''">
                <b-icon-check v-if="data.item.Value===true" variant="success" scale="2" class="mr-1"></b-icon-check>
                <b-icon-x v-else-if="data.item.Value===false" variant="danger" scale="2" class="mr-1"></b-icon-x>
                <b-icon-question v-else variant="danger" class="mr-1"></b-icon-question>
                {{ data.item.Value ? 'Ja' : 'Nein' }}
              </p>
              <p v-else-if="data.item.FeatureType === 'FeatureData::AddressFeature'">
                {{ data.item.Value.street }} {{ data.item.Value.number }}<br>
                {{ data.item.Value.Zip }} {{ data.item.Value.city }}<br>
                {{ data.item.Value.Country }}
                <a :href="'https://www.google.de/maps/place/' +
                  [
                    data.item.Value.street, data.item.Value.number, data.item.Value.Zip, data.item.Value.city,
                    data.item.Value.Country
                  ].join(',')"
                   target="_blank"
                   rel="noopener noreferrer">
                      <b-icon-geo-alt-fill></b-icon-geo-alt-fill> Auf Google suchen
                    </a>
              </p>
              <p v-else-if="data.item.FeatureType === 'FeatureData::AreaFeature'">
                {{ data.item.Value }}qm
              </p>
              <p v-else-if="data.item.FeatureType === 'FeatureData::PriceFeature'">
                {{ data.item.Value }}€
              </p>
              <p v-else-if="data.item.FeatureType === 'FeatureData::EnergyFeature'">
                {{ data.item.Value }}kWh/m²a
              </p>
              <p v-else>
                {{ data.item.Value }}
              </p>
            </template>
          </b-table>
        </div>
        <div class="col-12 text-right">
          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="Object.values(features).length"
            :per-page="pagination.perPage"
            align="right">
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PaginationMixin from '@/components/Pagination'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'real-estate-features',
  components: { PerPageSelect, AdvancedSearch },
  mixins: [
    PaginationMixin
  ],
  props: {
    features: {}
  },
  data () {
    return {
      search: '',
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Title',
          label: 'Titel',
          sortable: true
        },
        {
          key: 'Value',
          label: 'Wert',
          sortable: true
        },
        {
          key: 'FeatureType',
          label: 'Feature Typ',
          sortable: true
        }
      ]
    }
  }
}
</script>

<style scoped>
</style>

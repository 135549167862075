

import Vue from 'vue'
import axios from 'axios'
import { mutations } from '@/main'

export default Vue.extend({
  name: 'domain-form',
  components: { },
  props: {
    domain: Object,
    showNextButtons: Boolean,
    buttonLabel: {
      type: String,
      required: true
    }
  },
  data: function () {
    return {
      currentDomain: this.domain,
      customCrawlerOptions: []
    }
  },
  mounted () {
    this.loadCustomCrawlerOptions()
  },
  methods: {
    nullWhitelistIds (tag: any) {
      if (tag.Id === '') {
        tag.Id = 0
      }
    },
    openInNewTab (url: string) {
      const w = window.open(url, '_blank')
      if (w) {
        w.focus()
      }
    },
    loadCustomCrawlerOptions () {
      axios.get('crawler/api/custom_crawler/options')
        .then(r => {
          this.customCrawlerOptions = r.data
          console.log(this.customCrawlerOptions)
        })
    },
    restartCrawler: function () {
      this.$bvModal.msgBoxConfirm('Domain neu crawlen?',
        {
          title: 'Bestätigen',
          okTitle: 'Ja',
          cancelTitle: 'Nein',
          centered: true
        }
      )
        .then(value => {
          if (value) {
            axios.get(`crawler/api/domains/${this.currentDomain.Id}/crawl`)
              .then(r => {
                mutations.setSuccess('Domain wird neu geladen')
              })
              .catch(err => {
                mutations.setAlert(err)
              })
          }
        })
    },
    update (e: KeyboardEvent) {
      console.log(e)
      if (e.key === 's' && (e.ctrlKey || e.shiftKey || e.metaKey)) {
        this.$emit('next-save-button',
          this.currentDomain
        )
      }
    }
  }
})


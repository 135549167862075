<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card mb-4">
          <div class="card-header">Immopark - Admin Panel</div>
          <div class="card-body">
            <div v-if="user" class="row">
              <div class="col-12 col-md-9">
                <h5>Guten Tag {{ [user.Salutation, user.FirstName, user.LastName].join(' ') }}!</h5>
                <strong>Letzter Login: </strong> {{ new Date(user.LastLogin).toLocaleString() }}
                <div class="mt-4">
                  User: <router-link to="/users">{{ userStatistics.UserCount }}</router-link><br>
                  Makler mit aktiven Immobilien: <router-link to="/seller_profiles">{{ userStatistics.WithActiveEstates }}</router-link><br>
                  Suchprofile: {{ userStatistics.SearchProfiles }}
                </div>
              </div>
              <div class="col-12 col-md-3">
                <b-img :src="user.AvatarUrl" alt="Avatar" class="ip_avatar_medium" fluid/>
              </div>
            </div>
            <div v-else>
              <h6>Sie sind nicht eingeloggt!</h6>
            </div>
          </div>
        </div>

        <importer-stats class="mb-4" link-page></importer-stats>
        <messages-stats class="mb-4" link-page></messages-stats>
        <real-estates-stats class="mb-4" link-page></real-estates-stats>
        <services hide-details class="mb-4" link-page></services>

        <div class="card mb-4">
          <div class="card-header">Weitere Optionen</div>
          <div class="card-body">
            <a href="https://www.immopark.de/admin/dashboard">Dashboard im Portal besuchen</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Services from './Services'
import ImporterStats from '@/views/immopark/oi_import/ImporterStats'
import MessagesStats from '@/components/immopark/MessagesStats'
import RealEstatesStats from '@/views/immopark/real_estates/RealEstatesStats'
import { mutations } from '@/main'
import axios from 'axios'

export default {
  name: 'dashboard',
  components: {
    Services,
    ImporterStats,
    MessagesStats,
    RealEstatesStats
  },
  computed: {
    user () {
      return mutations.getLoggedInUser()
    }
  },
  data () {
    return {
      userStatistics: {
        UserCount: 0,
        WithActiveEstates: 0,
        SearchProfiles: 0
      }
    }
  },
  methods: {
    getUserStatistics () {
      axios.get('/user_statistics')
        .then(response => {
          this.userStatistics = response.data
        })
        .catch(error => {
          mutations.setAlert(error.response || error)
        })
    }
  },
  mounted () {
    this.getUserStatistics()
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="card">
    <div class="card-header">Willkommen bei Immopark</div>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <b-form>
            <b-form-group
              id="input-group-1"
              label="Email"
              label-for="input-1"
              description="Login email"
            >
              <b-form-input
                id="input-1"
                v-model="form.email"
                type="email"
                required
                placeholder="Enter email"
              ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label="Password" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="form.password"
                type="password"
                required
                placeholder="Enter password"
              ></b-form-input>
            </b-form-group>
            <b-button squared @click="submit">Login</b-button>
          </b-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'

export default {
  name: 'Login',
  data () {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    submit () {
      if ((this.form.email === '') || (this.form.password === '')) {
        alert('Bitte geben Sie ihre Email und Passwort an!')
      } else {
        const credentials = {
          Email: this.form.email,
          Password: this.form.password
        }
        axios.post('/authenticate', credentials)
          .then(response => {
            mutations.setLoggedInUser(response.data)
            this.$router.push({ name: 'Dashboard' })
          })
          .catch(error => {
            if (error.response.status === 401) {
              mutations.setAlert('Anmeldung fehlgeschlagen. E-Mail Adresse oder Passwort falsch.')
              return
            }
            mutations.setAlert(error)
          })
      }
    }
  }
}
</script>

<template>
  <div id="app" class="">

    <b-navbar toggleable="lg" style="margin-bottom: 15px" >
      <b-navbar-brand href="#">
        <img :src="require('@/assets/img/immopark_logo_slim.png')" alt="logo" height="38">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="#/dashboard">Immopark</b-nav-item>
          <b-nav-item href="#/crawler">Metasuche</b-nav-item>
        </b-navbar-nav>

        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown v-if="user" right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em> {{ user.Email }}</em>
            </template>
            <b-dropdown-item @click="logout" href="#">Abmelden</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-else href="#/login">Anmelden</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <b-alert :show="alert.show" :variant="alert.variant" @dismissed="alert.show=false" dismissible>
            <h5>{{ alert.message }}</h5>
            <p v-if="alert.showDetails">{{ alert.details }}</p>
          </b-alert>
          <b-alert :show="success.show" variant="success" @dismissed="success.show=false" dismissible>{{ success.message }}
            <p v-if="success.showDetails">{{ success.details }}</p>
          </b-alert>
        </div>
      </div>
      <div class="row">
        <div v-if="user" class="col-2">
          <div class="sticky-top" style="top: 65px">
            <Sidebar></Sidebar>
          </div>
        </div>
        <div v-else class="col-2`"></div>
        <main class="col-10" >
          <router-view/>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import { mutations, store } from '@/main'
import Sidebar from '@/views/Sidebar'
import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

Vue.component('v-select', vSelect)

export default {
  name: 'App',
  components: {
    Sidebar
  },
  data () {
    return {
    }
  },
  watch: {
  },
  computed: {
    user () {
      return mutations.getLoggedInUser()
    },
    storeData: () => {
      return store
    },
    menuVisible: () => {
      return !!mutations.getSessionToken()
    },
    alert: () => {
      return store.alert
    },
    success: () => {
      return store.success
    }
  },
  methods: {
    logout () {
      mutations.logout()
    },
    getEmail () {
      return mutations.getEmail()
    }
  }
}
</script>

<style>
.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 48px 0 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.title-row {
  border-bottom: 2px solid #00A500;
  font-size: 2rem;
  padding-top:15px;
  padding-bottom: 15px;
}
</style>

<template>
  <div class="row">
    <div class="col-12">
      <b-form-group
        label="Pro Seite"
        label-cols-sm="6"
        label-cols-md="4"
        label-cols-lg="3"
        label-align="right"
        label-align-sm="right"
        label-size="sm"
        label-for="perPageSelect"
        class="mb-0"
      >
        <b-form-select
          v-model="value.perPage"
          id="perPageSelect"
          size="sm"
          :options="value.perPageOptions"
          @change="() => this.$emit('input', value)"
        />
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'per-page-select',
  props: ['value'],
  data () {
    return {}
  },
  watch: {
  },
  methods: {
  }
}
</script>

<template>
  <div class="row">
    <div class="col-12">
      <b-table :items="items" :fields="fields" striped responsive="sm" empty-text="Keine laufenden Importe" show-empty>
        <template v-slot:cell(SellerProfile)="d">
            <a href="#">{{ d.item.SellerProfileName }}</a>
        </template>
        <template v-slot:cell(JobStatus)="d">
          <b-badge v-if="d.item.JobStatus==='Pending'" variant="info">
            <b-icon-three-dots class="mr-1"></b-icon-three-dots>{{ d.item.JobStatus }}
          </b-badge>
          <b-badge v-if="d.item.JobStatus==='Downloading'" variant="primary">
            <b-icon-download class="mr-1"></b-icon-download>{{ d.item.JobStatus }}
          </b-badge>
          <b-badge v-if="d.item.JobStatus==='Extracting'" variant="success">
            <b-icon-file-zip-fill class="mr-1"></b-icon-file-zip-fill>{{ d.item.JobStatus }}
          </b-badge>
          <b-badge v-if="d.item.JobStatus==='Parsing'" variant="success">
            <b-icon-card-list class="mr-1"></b-icon-card-list>{{ d.item.JobStatus }}
          </b-badge>
          <b-badge v-if="d.item.JobStatus==='Writing'" variant="success">
            <b-icon-hdd-fill class="mr-1"></b-icon-hdd-fill>{{ d.item.JobStatus }}
          </b-badge>
          <b-badge v-if="d.item.JobStatus==='Cleanup'" variant="info">
            <b-icon-bucket-fill class="mr-1"></b-icon-bucket-fill>{{ d.item.JobStatus }}
          </b-badge>
        </template>
        <template v-slot:cell(StatusText)="d">
          <code>{{ d.item.StatusText }}</code>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Oi-Import-Running',
  props: {
    items: {}
  },
  data () {
    return {
      fields: [
        {
          key: 'SellerProfileId',
          label: 'Id',
          sortable: true
        },
        {
          key: 'SellerProfile',
          label: 'Anbieter',
          sortable: true
        },
        {
          key: 'FileName',
          label: 'Datei',
          sortable: true
        },
        {
          key: 'JobStatus',
          label: 'Status',
          sortable: true
        }, {
          key: 'StatusText',
          label: 'Fortschritt',
          sortable: true
        }
      ],
      workers: [
      ],
      interval: 0
    }
  },
  methods: {
    confirmStartAllImports: function (item) {
      this.$bvModal.msgBoxConfirm('Dies startet alle Importe von ' + item.SellerProfileName + '.', {
        title: 'Bestätigen',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            alert('Mock: Import gestartet für ' + item.SellerProfileId)
          }
        })
        .catch(err => {
          alert(err)
          // An error occurred
        })
    },
    confirmStopAllImports: function (item) {
      this.$bvModal.msgBoxConfirm('Dies startet alle Importe von ' + item.SellerProfileName + '.', {
        title: 'Bestätigen',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            alert('Mock: Import gestoppt für ' + item.SellerProfileId)
          }
        })
        .catch(err => {
          alert(err)
          // An error occurred
        })
    }
  },
  mounted () {
  },
  destroyed () {
  }
}
</script>

<style scoped>
</style>

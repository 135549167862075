<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">Nutzerprofile</div>
      <div class="card-body">
        <div class="row mb-2">
          <div class="col-12 col-lg-8">
            <advanced-search v-model="search"></advanced-search>
          </div>
          <div class="col-12 col-lg-4">
            <per-page-select v-model="pagination"/>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <b-table id="sp-table"
                     ref="spTable"
                     :items="items"
                     :fields="fields"
                     :per-page="pagination.perPage"
                     :busy="isBusy"
                     :filter="search"
                     :current-page="pagination.currentPage"
                     striped>
              <template v-slot:cell(Id)="data">
                <router-link :to="'/users/'+data.item.Id">{{data.item.Id}}</router-link>
              </template>
              <template v-slot:cell(Name)="data">
                <div class="row">
                  <div class="col-12">
                    <router-link :to="'/users/'+data.item.Id">
                      <b-icon-person class="mr-1"></b-icon-person><span v-if="data.item.FirstName || data.item.LastName">{{ data.item | formatName }}</span>
                      <span v-else>{{ data.item.Email }}</span>
                    </router-link>
                  </div>
                  <div class="col-12">
                    <a :href="'mailto:' + data.item.Email">
                      <b-icon-envelope class="mr-1"></b-icon-envelope>{{ data.item.Email }}
                    </a>
                  </div>
                  <div class="col-12">
                    <a v-if="getPhone(data.item)" :href="'tel:' + getPhone(data.item)">
                      <b-icon-phone class="mr-1"></b-icon-phone>{{ getPhone(data.item) }}
                    </a>
                  </div>
                </div>
              </template>
              <template v-slot:cell(SellerProfile)="data">
                <router-link v-if="data.item.SellerProfileId" :to="'/seller_profiles/' + data.item.SellerProfileId">
                  {{ data.item.SellerProfileName }} ({{ data.item.SellerProfileId }})
                </router-link>
              </template>
              <template v-slot:cell(Address)="data">
                <address-column :item="data.item">
                </address-column>
              </template>
              <template v-slot:cell(UserType)="data">
                {{ data.item.UserType | formatUserType }}
              </template>
              <template v-slot:cell(Functions)="data">
                <a href="#" class="m-0 danger" @click="onDeleteUser(data.item)">
                  <b-icon-trash-fill class="mr-1"></b-icon-trash-fill>Löschen
                </a>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="rowsFiltered"
              :per-page="pagination.perPage"
              aria-controls="sp-table"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import AddressColumn from '@/components/common/AddressColumn'
import { mutations } from '@/main'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'user-profiles',
  components: {
    AddressColumn,
    AdvancedSearch,
    PerPageSelect
  },
  mixins: [
    PaginationMixin
  ],
  props: {
  },
  data () {
    return {
      search: '',
      searchById: '',
      searchByFields: '',
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Name',
          sortable: true
        },
        {
          key: 'SellerProfile',
          label: 'Anbieter',
          sortable: true
        },
        {
          key: 'Address',
          label: 'Anschrift',
          sortable: true
        },
        {
          key: 'UserType',
          label: 'Nutzertyp',
          sortable: true
        },
        {
          key: 'Functions',
          label: 'Optionen'
        }
      ],
      showUserType: 'registered-only',
      userProfileId: 0,
      currentUserProfile: {},
      rowsTotal: 0,
      rowsFiltered: 0,
      isBusy: false
    }
  },
  filters: {
    formatName (item) {
      let name = ''
      if (item.Salutation) {
        name += item.Salutation + ' '
      }
      if (item.FirstName) {
        name += item.FirstName + ' '
      }
      if (item.LastName) {
        name += item.LastName
      }
      return name
    },
    formatUserType (userType) {
      switch (userType) {
        case 'broker':
          return 'Makler'
        case 'user':
          return 'Nutzer'
        case 'anonymous':
          return 'Anonym'
        default:
          return userType
      }
    }
  },
  methods: {
    getPhone (item) {
      let phone = '';
      [item.PhoneExtension, item.PhoneCentral, item.PhoneMobile, item.PhonePrivate, item.PhoneOther]
        .forEach(p => { if (p) { phone = p } })
      return phone
    },
    items (ctx, callback) {
      let q = '/users'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&search=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDesc
      q += '&user_type=' + this.showUserType
      axios.get(q)
        .then(res => {
          const items = res.data.data
          this.rowsFiltered = res.data.recordsFiltered
          this.rowsTotal = res.data.recordsTotal
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    onSubmit (event) {
      event.preventDefault()
    },
    onDeleteUser: function (item) {
      const awn = this.$awn
      awn.confirm(
        'Dieser Benutzer wird endgültig gelöscht?',
        () => {
          axios.delete('/users/' + item.Id)
            .then((response) => {
              mutations.setSuccess('Benutzer erfolgreich gelöscht')
            })
            .catch((error) => {
              if (error.response.data.Error === 'delete seller profile first') {
                awn.confirm('<div> Dieser Nutzer ist als Besitzer des Maklerprofils <em>' + item.SellerProfileId + `</em>
                  eingetragen. Bitte löschen Sie zuerst das zugehörige Maklerprofil.</div>`,
                null, false,
                {
                  labels: {
                    confirm: 'Fehler ' + error.response.data.Code
                  }
                })
              } else {
                awn.confirm(error.response.data.Error,
                  null, false,
                  {
                    labels: {
                      confirm: 'Fehler ' + error.response.data.Code
                    }
                  })
              }
            })
        },
        null,
        {
          labels: {
            confirm: 'Sind Sie sicher?',
            ok: 'Ja',
            cancel: 'Nein'
          }
        })
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  .vert {
    display: block;
  }
</style>

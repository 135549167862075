<template>
   <div class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
    <div class="flex-1 flex justify-between sm:hidden">
      <a href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
        Previous
      </a>
      <a href="#" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
        Next
      </a>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm leading-5 text-gray-700">
          Showing
          <span class="font-medium">1</span>
          to
          <span class="font-medium">10</span>
          of
          <span class="font-medium">{{total}}</span>
          results
        </p>
      </div>
      <div>
        <nav class="relative z-0 inline-flex shadow-sm">
          <a href="#" @click="previousPage" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Previous">
            <svg class="h-5 w-5" x-description="Heroicon name: chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd"></path>
            </svg>
          </a>
          <a v-for="(page, i) in pages" :key="i">
            <a  v-if="page.isDisabled" href="#" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-immo text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">{{page.name}}</a>
            <span v-else-if="page.isDotted" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700"> ... </span>
            <a v-else href="#" @click="clickPage(page.name)" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">{{page.name}}</a>
          </a>
          <a href="#"  @click="nextPage" class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150" aria-label="Next">
            <svg class="h-5 w-5" x-description="Heroicon name: chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd"></path>
            </svg>
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    total: {
      type: Number,
      required: true
    },
    perPage: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  methods: {
    clickPage (i) {
      this.$emit('pagechanged', i)
    },
    previousPage () {
      if (this.currentPage > 1) {
        this.$emit('pagechanged', this.currentPage - 1)
      }
    },
    nextPage () {
      if (this.currentPage < this.totalPages) {
        this.$emit('pagechanged', this.currentPage + 1)
      }
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.total / this.perPage)
    },
    pages () {
      const range = []

      if (this.total < this.maxVisibleButtons) {
        for (let i = 1; i <= this.totalPages; i++) {
          range.push({
            name: i,
            isDisabled: i === this.currentPage,
            isDotted: false
          })
        }
        return range
      }

      const h = Math.round(this.maxVisibleButtons / 2)

      for (let i = 0; i < this.maxVisibleButtons; i++) {
        let index = 0
        let dotted = false

        if (this.currentPage <= h) {
          // start of the range
          index = i + 1
          if (i === this.maxVisibleButtons - 1) {
            dotted = true
          }
        } else if (this.currentPage - h > this.maxVisibleButtons) {
          // end of the range
          index = i + this.totalPages - h - 1
          if (i === 0) {
            dotted = true
          }
        } else {
          index = i + this.currentPage - h + 1
        }

        range.push({
          name: index,
          isDisabled: index === this.currentPage,
          isDotted: dotted
        })
      }
      return range
    }
  },
  data: function () {
    return {
      maxVisibleButtons: 5
    }
  }
}
</script>

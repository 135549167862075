<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        Ergebnisse
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-8 mb-2">
            <b-input-group
              id="fetcher-search-group"
              label-for="fetcher-search-input"
              description="Nach Domains suchen"
              size="sm"
              class=""
            >
              <b-form-input
                id="fetcher-search-input"
                v-model="searchOptions.search"
                type="text"
                placeholder="nach beliebigen Feldern suchen"
                class=""
                debounce="500"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button @click="searchOptions.search = ''" :disabled="searchOptions.search === ''">Filter entfernen</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12 col-md-4">
            <b-form-group
              label="Pro Seite"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align="right"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="searchOptions.itemsPerPage"
                id="perPageSelect"
                size="sm"
                :options="perPageOptions"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-tabs>
              <b-tab title="Gültig">
                <b-table id="table" ref="table"
                         :items="getValidResults" :fields="fields"
                         :per-page="searchOptions.itemsPerPage" :current-page="searchOptions.currentPage"
                         :filter="searchOptions.search" :empty-text="'Keine Ergebnisse gefunden'"
                         :empty-filtered-text="'Keine Ergebnisse zur Suchanfrage gefunden'"
                         striped hover @row-clicked="rowClicked"
                         selectable select-mode="single">
                  <template v-slot:cell(id)="data">
                    {{ data.item.id }}
                  </template>
                  <template v-slot:cell(title)="data">
                    <a :id="'a_' + data.item.id" :href="data.item.url" target="_blank">
                      {{ data.item.title }}<br>
                    </a>
                    <a>
                      <small>{{ data.item.url }}</small>
                    </a>
                  </template>
                  <template v-slot:cell(last_seen)="data">
                    {{ new Date(data.item.last_seen).toLocaleString() }}
                  </template>
                  <template v-slot:cell(created_at)="data">
                    {{ new Date(data.item.created_at).toLocaleString() }}
                  </template>
                  <template #row-details="row">
                    <div class="row">
                      <div class="col-12">
                        <json-view :data="row.item" class="result-json" :maxDepth="5"></json-view>
                      </div>
                    </div>
                  </template>
                </b-table>
                <div class="col-12">
                  <b-pagination v-model="searchOptions.currentPage" :per-page="searchOptions.itemsPerPage" :total-rows="validRowsTotal" align="right"></b-pagination>
                </div>
              </b-tab>
              <b-tab title="Ungültig">
                <b-table id="table" ref="table"
                         :items="getInvalidResults" :fields="fields"
                         :per-page="searchOptions.itemsPerPage" :current-page="searchOptions.currentPage"
                         :filter="searchOptions.search" :empty-text="'Keine Ergebnisse gefunden'"
                         :empty-filtered-text="'Keine Ergebnisse zur Suchanfrage gefunden'"
                         striped hover @row-clicked="rowClicked"
                         selectable select-mode="single">
                  <template v-slot:cell(id)="data">
                    {{ data.item.id }}
                  </template>
                  <template v-slot:cell(title)="data">
                    <a :id="'a_' + data.item.id" :href="data.item.url" target="_blank">
                      {{ data.item.title }}<br>
                    </a>
                    <a>
                      <small>{{ data.item.url }}</small>
                    </a>
                  </template>
                  <template v-slot:cell(last_seen)="data">
                    {{ new Date(data.item.last_seen).toLocaleString() }}
                  </template>
                  <template v-slot:cell(created_at)="data">
                    {{ new Date(data.item.created_at).toLocaleString() }}
                  </template>
                  <template #row-details="row">
                    <div class="row">
                      <div class="col-12">
                        <json-view :data="row.item" class="result-json" :maxDepth="5"></json-view>
                      </div>
                    </div>
                  </template>
                </b-table>
                <div class="col-12">
                  <b-pagination v-model="searchOptions.currentPage" :per-page="searchOptions.itemsPerPage" :total-rows="invalidRowsTotal" align="right"></b-pagination>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import { JSONView } from 'vue-json-component'

export default {
  name: 'results',
  components: {
    'json-view': JSONView
  },
  data: function () {
    return {
      searchOptions: {
        itemsPerPage: 25,
        search: '',
        currentPage: 1,
        sortBy: '',
        sortDesc: true
      },
      perPageOptions: [10, 25, 50, 100],
      domains: [],
      validItems: [],
      invalidItems: [],
      fields: [
        {
          key: 'id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'title',
          label: 'Url',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Zuerst gesehen',
          sortable: true
        },
        {
          key: 'last_seen',
          label: 'Zuletzt gesehen',
          sortable: true
        }
      ],
      invalidRowsTotal: 0,
      validRowsTotal: 0
    }
  },
  mounted () {
  },
  methods: {
    getInvalidResults (ctx, callback) {
      const q = `crawler/api/domains/${this.$route.params.id}/results?page=${ctx.currentPage}&order_by=${ctx.sortBy}&order=${ctx.sortDesc ? 'desc' : 'asc'}&per_page=${ctx.perPage}&query_invalid=only_invalid`
      axios.get(q)
        .then(res => {
          this.invalidRowsTotal = res.data.Total
          this.recordsFiltered = res.data.recordsFiltered
          this.recordsTotal = res.data.recordsTotal
          callback(res.data.Documents)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    getValidResults (ctx, callback) {
      const q = `crawler/api/domains/${this.$route.params.id}/results?page=${ctx.currentPage}&order_by=${ctx.sortBy}&order=${ctx.sortDesc ? 'desc' : 'asc'}&per_page=${ctx.perPage}&query_invalid=only_valid`
      axios.get(q)
        .then(res => {
          this.validRowsTotal = res.data.Total
          callback(res.data.Documents)
        })
        .catch((error) => {
          console.log(error)
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    rowClicked (row) {
      row._showDetails = !row._showDetails
    },
    truncate (str, n) {
      if (str === undefined || str === '') {
        return ''
      }
      return (str.length > n) ? str.substr(0, n - 1) + '…' : str
    },
    showUrl (url) {
      this.$bvModal.msgBoxOk(url, {
        size: 'xl',
        buttonSize: 'sm',
        okVariant: 'success',
        contentClass: 'p-2 wrap',
        scrollable: true,
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
    }
  }
}
</script>

<style scoped>
  .background {
    display:flex;
    justify-content: center;
    align-items: center;
    background-image: url(/assets/img/search_bgn.jpg);
    background-size: cover;
    overflow:hidden;
  }

  .immorahmen {
    width: 1680px;
    height: 80vh;
    margin-top: 26px;
    margin-bottom: 26px;
    overflow:scroll;
  }

  .whiteroom {
    background-color:white;
    padding:3rem;
    border-radius: 4px;
    border: 1px solid #707070;
    opacity: 1;
    min-height:80%;
  }
  .bild {
    width:150px;
    height: 150px;
    background-color: #01a500;
  }
  .id {
    width:150px;
  }

  .wrap {
    overflow-wrap: normal;
    word-wrap: break-word;
  }
  .result-json {
    width: auto;
    height: auto;
    background-color: inherit;
    overflow: auto;
    max-height: 400px;
  }
</style>

<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mb-2">
        <div class="card">
          <div class="card-header">Immobilien</div>
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-8">
                <advanced-search v-model="search"></advanced-search>
              </div>
              <div class="col-12 col-md-4">
                <per-page-select v-model="pagination"></per-page-select>
              </div>
              <div class="col-12 col-md-5">
                <b-form-checkbox-group
                  id="filter-status-group"
                  v-model="filterStatus"
                  :options="filterStatusOptions"
                  name="filter-status-group"
                  @change="$refs.table.refresh()"
                ></b-form-checkbox-group>
              </div>
            </div>
            <div class="col-12 mt-4">
              <b-table id="table" ref="table"
                       :fields="fields" :items="items"
                       :per-page="pagination.perPage" :current-page="1"
                       :filter="search"
                       :busy="isBusy"
                       @row-clicked="toogleRow"
                       selectable
                       select-mode="single"
                       striped hover>
                <template v-slot:cell(Id)="data">
                  <router-link :to="'/real_estates/' + data.item.Id">{{data.item.Id}}</router-link>
                </template>
                <template v-slot:cell(Title)="data">
                  <router-link :to="'/real_estates/' + data.item.Id">{{data.item.Headline}}</router-link><br>
                  <a :href="'https://immopark.de/immobilien/' + data.item.Id" target="_blank"><b-icon-link></b-icon-link>Auf immopark.de besuchen</a>
                </template>
                <template v-slot:cell(ContactPerson)="data">
                  <router-link v-if="data.item.ContactPerson" :to="'/users/' + data.item.ContactPersonId">
                    <b-icon-person></b-icon-person>
                    {{ [data.item.ContactPerson.Salutation,
                        data.item.ContactPerson.FirstName,
                        data.item.ContactPerson.LastName].join(' ')
                    }}</router-link>
                  <span v-else>keine Kontaktperson</span>
                </template>-->
                <template v-slot:row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Erstellt am:</b></b-col>
                      <b-col>{{ new Date(row.item.CreatedAt).toLocaleString() }}</b-col>
                    </b-row>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Aktualisiert am:</b></b-col>
                      <b-col>{{ new Date(row.item.UpdatedAt).toLocaleString() }}</b-col>
                    </b-row>
                  </b-card>
                </template>
              </b-table>
            </div>
            <div class="col-12">
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="recordsFiltered"
                :per-page="pagination.perPage"
                aria-controls="table"
                align="right"
                @change="$refs.table.refresh()"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'RealEstates',
  components: {
    PerPageSelect,
    AdvancedSearch
  },
  mixins: [
    PaginationMixin
  ],
  data () {
    return {
      isBusy: false,
      search: '',
      filterStatus: [],
      filterStatusOptions: [
        {
          text: 'Gelöscht',
          value: 'deleted'
        },
        {
          text: 'Versteckt',
          value: 'hidden'
        },
        {
          text: 'Verkauft',
          value: 'sold'
        },
        {
          text: 'Sichtbar',
          value: 'visible'
        }
      ],
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'ProviderIdentifier',
          label: 'Anbieter Id',
          sortable: true
        },
        {
          key: 'Title',
          label: 'Titel',
          sortable: true
        },
        {
          key: 'CurrentStatus',
          label: 'Status',
          sortable: true
        },
        {
          key: 'ContactPerson',
          label: 'Kontaktperson',
          sortable: true
        }
      ],
      estates: [],
      filtered: [],
      orderBy: 'id',
      desc: false,
      totalRows: 0,
      recordsTotal: 0,
      recordsFiltered: 0,
      statsCount: {}
    }
  },
  watch: {
  },
  computed: {
  },
  methods: {
    toogleRow (row) {
      row._showDetails = !row._showDetails
    },
    items (ctx, callback) {
      let q = '/real_estates'
      q += '?start=' + (this.pagination.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&search=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDesc
      q += '&filter_status=' + this.filterStatus.join(',')
      axios.get(q)
        .then(res => {
          const items = res.data.data
          this.recordsFiltered = res.data.recordsFiltered
          this.recordsTotal = res.data.recordsTotal
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    getStats: function () {
      axios.get('/real_estates/stats')
        .then(response => {
          this.statsCount.datasets[0].data = [
            response.data.deleted,
            response.data.hidden,
            response.data.sold,
            response.data.visible
          ]
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
        })
    }
  },
  mounted () {
    this.getStats()
  }
}

</script>

<style scoped>

</style>

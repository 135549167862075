<template>
    <div class="card">
      <div class="card-header">Services</div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 text-right mb-2">
            <router-link v-if="linkPage" to="/services">zur Serviceübersicht gehen</router-link>
          </div>
          <div class="col-12">
            <b-list-group>
              <b-list-group-item v-for="(item, index) in services" :key="index">
                <h6>{{ item.name }}</h6>
                <b-alert v-if="item.status==='online'" variant="success" show>
                  <span>{{ item.status }}</span>
                  <json-view v-if="!hideDetails"
                             :data="item.statusResponse"
                             class ="status-json"
                             :maxDepth="0"
                             rootKey="Status">
                  </json-view>
                </b-alert>
                <b-alert v-else-if="item.status==='unknown'" variant="dark" show>
                  Unbekannt
                </b-alert>
                <b-alert v-else variant="danger">
                  Offline
                </b-alert>
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import { JSONView } from 'vue-json-component'

export default {
  name: 'Services',
  components: {
    'json-view': JSONView
  },
  props: {
    linkPage: Boolean,
    hideDetails: Boolean
  },
  data () {
    return {
      loaded: false,
      reloadInterval: null,
      services: [
        {
          name: 'Places',
          statusUrl: process.env.VUE_APP_API_PATH + '/status',
          status: 'unknown',
          statusResponse: {}
        },
        {
          name: 'Immopark API',
          statusUrl: process.env.VUE_APP_API_PATH + '/status',
          status: 'unknown',
          statusResponse: {}
        },
        {
          name: 'Geocoder',
          statusUrl: process.env.VUE_APP_GEOCODER_PATH + '/status?token=' + process.env.VUE_APP_GEOCODER_TOKEN,
          status: 'unknown',
          statusResponse: {}
        },
        {
          name: 'Portal',
          statusUrl: process.env.VUE_APP_IMMOPARK_PATH + '/status',
          status: 'unknown',
          statusResponse: {}
        },
        {
          name: 'Redis',
          statusUrl: process.env.VUE_APP_REDIS_PATH + '/status',
          status: 'unknown',
          statusResponse: {}
        }
      ]
    }
  },
  methods: {
    expandRow (row) {
      row._showDetails = !row._showDetails
    },
    reloadServices () {
      this.servicesStatus = []
      this.services.forEach(srv => {
        axios.get(srv.statusUrl)
          .then(response => {
            srv.status = response.status === 200 ? 'online' : response.status.toString()
            if (srv.name === 'Redis') {
              srv.statusResponse = JSON.parse(response.data.replace(/=>/g, ':'))
            } else {
              srv.statusResponse = response.data
            }
          })
          .catch(error => {
            srv.statusResponse = error.response ? error.response.data : error
          })
      })
    }
  },
  mounted () {
    this.reloadServices()
  },
  beforeDestroy () {
    clearInterval(this.reloadInterval)
  }
}
</script>

<style scoped>
.status-json {
  width: auto;
  height: auto;
  background-color: inherit;
  overflow: auto;
  max-height: 400px;
}
</style>

<template>
  <div class="container-fluid">
    <div class="row">
    <div class="col-12 card">
      <div class="card-header">
        Importer
        <h5 class="float-right">
          <b-badge :variant="status==='running' ? 'success' : 'danger'" >
            {{ status==='running' ? 'Läuft...' : 'Gestoppt' }}
          </b-badge>
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 mb-2">
            <json-view :data="{ jobs: numJobs, interval: interval }" style="overflow: auto" :maxDepth="1" rootKey="Status"></json-view>
          </div>
          <div class="col-12">
            <b-tabs content-class="mt-3" lazy>
              <b-tab title="Laufend" active>
                <oi-import-running :items="running" class="w-100 mw-100"></oi-import-running>
              </b-tab>
              <b-tab title="Ausstehend">
                <oi-import-pending :data="pending" class="w-100 mw-100"></oi-import-pending>
              </b-tab>
              <b-tab title="Abgeschlossen">
                <oi-import-finished></oi-import-finished>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import OiImportRunning from './oi_import/OiImportRunning'
import OiImportFinished from './oi_import/OiImportFinished'
import OiImportPending from './oi_import/OiImportPending'

export default {
  name: 'Oi-Import',
  components: {
    'oi-import-running': OiImportRunning,
    'oi-import-pending': OiImportPending,
    'oi-import-finished': OiImportFinished
  },
  data () {
    return {
      updateInterval: null,
      importerRunning: false,
      running: [],
      pending: {},
      status: 'stopped',
      numJobs: 0,
      interval: 0
    }
  },
  methods: {
    updateLoop () {

    },
    getData () {
      // MOCK: 'http://localhost:3000/api/import_jobs/status'
      axios.get('/import_jobs/status') // Get the jobs currently running
        .then(response => {
          this.running = response.data.Workers
          this.status = response.data.Status
          this.numJobs = response.data.NumJobs
          this.interval = response.data.Interval
        })
        .catch(error => {
          mutations.setAlert(error)
        })
      // MOCK: 'http://localhost:3000/api/import_jobs'
      axios.get('/import_jobs') // Get pending jobs
        .then(response => {
          this.pending = response.data
        })
        .catch(error => {
          mutations.setAlert(error)
        })
    }
  },
  mounted () {
    this.getData()
    this.updateInterval = setInterval(this.getData, 5000)
  },
  beforeDestroy () {
    clearInterval(this.updateInterval)
  }
}
</script>

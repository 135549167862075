<template>
  <div class="card">
    <div class="card-header">
      Immobilien
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-12 text-right mb-2">
          <router-link to="/real_estates">zur Immobilienseite gehen</router-link>
        </div>
        <div class="col-12">
          <b-table-lite :fields="fields" :items="[stats]">
          </b-table-lite>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'

export default {
  name: 'real-estates-stats',
  components: { },
  props: {
    linkPage: Boolean
  },
  data () {
    return {
      stats: {},
      fields: [
        {
          key: 'visible',
          label: 'Sichtbar'
        },
        {
          key: 'hidden',
          label: 'Versteckt'
        },
        {
          key: 'sold',
          label: 'Verkauft'
        },
        {
          key: 'deleted',
          label: 'Gelöscht'
        },
        {
          key: 'total',
          label: 'Gesamt'
        }
      ]
    }
  },
  watch: {
  },
  methods: {
    getStats: function () {
      axios.get('/real_estates/stats')
        .then(response => {
          this.stats = response.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
        })
    },
    getChartData () {
      return {
        labels: ['Gelöscht', 'Versteckt', 'Verkauft', 'Sichtbar'],
        datasets: [
          {
            label: 'Immobilien',
            data: [
              this.stats.deleted,
              this.stats.hidden,
              this.stats.sold,
              this.stats.visible
            ],
            backgroundColor: [
              'rgb(255,0,8)',
              'rgb(128,128,128)',
              'rgb(0,0,128)',
              'rgb(0, 256, 0)'
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.getStats()
  }
}
</script>

<style scoped>
</style>

<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">Makler Profile aufzählen</div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-8">
            <advanced-search v-model="search" disable-id-search/>
          </div>
          <div class="col-12 col-md-4">
            <per-page-select v-model="pagination"></per-page-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <b-form-select v-model="selected" :options="options" @change="requestCountBy"></b-form-select>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <b-table id="table" :items="result" :fields="fields" :filter="search" :per-page="pagination.perPage" :current-page="pagination.currentPage">
              <template v-slot:cell(key)="data">
                <a :href="'https://www.google.de/maps/search/' + data.item.key" target="_blank" v-b-tooltip="'Auf Google suchen'"><b-icon-geo-alt class="mr-1"></b-icon-geo-alt>{{ data.item.key }}</a>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="rowsFiltered"
              :per-page="pagination.perPage"
              aria-controls="table"
              align="right"
              size="sm"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'
export default {
  name: 'CountSellerProfiles',
  components: {
    PerPageSelect,
    AdvancedSearch
  },
  mixins: [
    PaginationMixin
  ],
  data () {
    return {
      selected: 'city',
      rowsFiltered: 0,
      search: '',
      options: [
        { value: null, text: 'Please select an option' },
        { value: 'city', text: 'Nach Städten auflisten' },
        { value: 'zip', text: 'Nach Postleitzahlen auflisten' },
        { value: 'country', text: 'Nach Ländern auflisten' }
      ],
      fields: [
        {
          key: 'key',
          label: this.selected,
          sortable: true
        },
        {
          key: 'count',
          label: 'Makler',
          sortable: true
        }
      ],
      result: []
    }
  },
  methods: {
    requestCountBy: function () {
      if (!this.selected) {
        return
      }
      axios.get('/count_seller_profiles/' + this.selected)
        .then(response => {
          this.result = []
          for (const [k, v] of Object.entries(response.data)) {
            this.result.push({
              key: k,
              count: v
            })
          }
          this.rowsFiltered = this.result.length
        })
        .catch((error) => {
          alert(error.message)
        })
    },
    ListItemProvider: function () {
      const items = []
      Object.keys(this.result).forEach((key) => {
        items.push({
          key: key,
          count: this.result[key]
        })
        // key: the name of the object key
        // index: the ordinal position of the key within the object
      })
      return items
    }
  },
  mounted () {
    this.requestCountBy()
  }
}
</script>

<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">Aktivitäten</div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-8 mb-2">
            <b-input-group size="sm" label-for="sps-messages-search-input" description="Nach Nachrichten suchen">
              <b-form-input
                id="sps-messages-search-input"
                v-model="filter"
                type="text"
                placeholder="nach beliebigen Feldern suchen"
                class=""
                debounce="500"
                @change="getActivities"
              >
              </b-form-input>
              <div class="input-group-append">
                <b-button variant="secondary" squared :disabled="filter===''" @click="filter=''">
                  Filter entfernen
                </b-button>
              </div>
            </b-input-group>
          </div>
          <div class="col-12 col-md-4 mb-2">
            <b-select v-model="pagination.perPage" :options="pagination.perPageOptions" size="sm">
            </b-select>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-table ref="table" :items="items" :fields="fields" :filter="search"
                     :current-page="pagination.currentPage" :per-page="pagination.perPage" striped
            >
              <template v-slot:cell(SellerProfileId)="data">
                <router-link :to="'/seller_profiles/' + data.item.SellerProfileId">
                  {{ data.item.SellerProfileId }}
                </router-link>
              </template>
              <template v-slot:cell(Name)="data">
                <router-link :to="'/seller_profiles/' + data.item.SellerProfileId">
                  {{ data.item.Name }}
                </router-link>
              </template>
              <template v-slot:cell(UpdatedAt)="data">
                {{ new Date(data.item.UpdatedAt).toLocaleString() }}
              </template>
              <template v-slot:cell(LastEstateUpdate)="data">
                {{ new Date(data.item.LastEstateUpdate).toLocaleString() }}
                <b-badge variant="info">{{ data.item.LastEstateUpdateStatus }}</b-badge>
              </template>
              <template v-slot:cell(OldestVisible)="data">
                <span v-if="data.item.OldestVisible">{{ new Date(data.item.OldestVisible).toLocaleString() }}</span>
              </template>
              <template v-slot:cell(NewestVisible)="data">
                <span v-if="data.item.NewestVisible">{{ new Date(data.item.NewestVisible).toLocaleString() }}</span>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="rowsTotal"
              :per-page="pagination.perPage"
              aria-controls="table"
              size="sm"
              align="right"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mutations } from '@/main'
import axios from 'axios'
import PaginationMixin from '@/components/PaginationMixin'

export default {
  name: 'sp-activities',
  mixins: [
    PaginationMixin
  ],
  data () {
    return {
      fields: [
        {
          key: 'SellerProfileId',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Makler',
          sortable: true
        },
        {
          key: 'UpdatedAt',
          label: 'Letzte Profiländerung',
          sortable: true
        },
        {
          key: 'LastEstateUpdate',
          label: 'Letzte Immobilienänderung',
          sortable: true
        },
        {
          key: 'OldestVisible',
          label: 'Älteste',
          sortable: true
        },
        {
          key: 'NewestVisible',
          label: 'Neueste',
          sortable: true
        },
        {
          key: 'VisibleEstates',
          label: 'Sichtbare Immobilien',
          sortable: true
        },
        {
          key: 'Estates',
          label: 'Immobilien',
          sortable: true
        }
      ],
      searchByFields: '',
      searchById: '',
      search: '',
      rowsTotal: 0,
      rowsFiltered: 0,
      isBusy: false,
      items: []
    }
  },
  watch: {
    searchById () {
      if (this.searchEmpty) {
        this.search = ''
        return
      }
      if (this.searchById) {
        this.searchByFields = ''
        this.search = 'id:' + this.searchById
      }
    },
    searchByFields () {
      if (this.searchEmpty) {
        this.search = ''
        return
      }
      if (this.searchByFields) {
        this.searchById = ''
        this.search = this.searchByFields
      }
    }
  },
  computed: {
    searchEmpty () {
      return this.searchByFields === '' && this.searchById === ''
    }
  },
  methods: {
    resetSearch () {
      this.searchByFields = ''
      this.searchById = ''
      this.search = ''
    },
    getActivities () {
      axios.get('/seller_profiles/activity')
        .then(res => {
          this.items = res.data
          this.rowsTotal = res.data.length
          this.isBusy = false
        })
        .catch((error) => {
          mutations.setAlert(error)
          this.isBusy = false
        })
    }
  },
  mounted () {
    this.getActivities()
  }
}
</script>

<style scoped>

</style>

<template>
  <!--TODO Get nice design for this-->
  <div v-if="contact" class="card">
    <div class="card-header">Kontakt</div>
    <div class="row mx-1 my-2">
      <div class="col-12 col-md-4">
        <b-img :src="contact.AvatarFileName || 'https://immopark.de/assets/default_avatar/default_avatar.png'"
               fluid thumbnail></b-img>
      </div>
      <div class="col-12 col-md-8 my-2">
        <div class="row">
          <div class="col-12 mb-4">
            <p class="mb-0" v-if="contactFullName">{{ contactFullName }}</p>
            <p class="mb-0" v-if="contactStreet">{{ contactStreet }}</p>
            <p class="mb-0" v-if="contactCity">{{ contactCity }}</p>
            <p class="mb-0" v-if="contact.Country">{{ contact.Country }}</p>
          </div>
          <div class="col-12">
            <p class="mb-0">
              <a v-if="contact.Email" :href="'mailto:' + contact.Email">
                <b-icon-envelope-fill class="mr-1"></b-icon-envelope-fill>{{ contact.Email }}</a>
            </p>
            <p class="mb-0">
              <a v-if="contact.PhoneExtension" :href="'tel:' + contact.PhoneExtension">
                <b-icon-phone-fill class="mr-1"></b-icon-phone-fill>{{ contact.PhoneExtension }}</a>
              <a v-else-if="contact.PhoneCentral" :href="'tel:' + contact.PhoneCentral">
                <b-icon-phone-fill class="mr-1">{{ contact.PhoneCentral }}</b-icon-phone-fill></a>
              <a v-else-if="contact.PhonePrivate" :href="'tel:' + contact.PhonePrivate">
                <b-icon-phone-fill>{{ contact.PhonePrivate }}</b-icon-phone-fill></a>
              <a v-else-if="contact.PhoneOther" :href="'tel:' + contact.PhoneOther">
                <b-icon-phone-fill></b-icon-phone-fill>{{ contact.PhoneOther }}</a>
            </p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <router-link class="float-right" :to="{ name: 'UserProfileEdit', params: { id: contact.Id } }">
          <b-icon-pencil-square></b-icon-pencil-square>Nutzer bearbeiten
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'contact-card',
  props: {
    contact: {
      AvatarFileName: ''
    }
  },
  watch: {
    contact: {
      handler: function (val, oldVal) {
        // no kittenizing in staging or master :(
        // this.randomizeKitten()
      },
      deep: true
    }
  },
  computed: {
    contactFullName: function () {
      return [this.contact.Salutation, this.contact.Title, this.contact.FirstName, this.contact.LastName].join(' ')
    },
    contactStreet: function () {
      return [this.contact.Street, this.contact.HouseNumber].join(' ')
    },
    contactCity: function () {
      return [this.contact.Zip, this.contact.City].join(' ')
    }
  },
  methods: {
    randomizeKitten: function () {
      if ((this.contact === undefined) || (this.contact === null)) {
        return
      }
      this.contact.AvatarFileName = 'https://placekitten.com/200/200?image=' + (Math.floor(Math.random() * 10) + 1)
    },
    getName: function () {
      if ((this.contact === undefined) || (this.contact === null)) {
        return
      }
      let name = ''
      if (this.contact.Salutation !== undefined && this.contact.Salutation !== '') {
        name += this.contact.Salutation + ' '
      }
      if (this.contact.FirstName !== undefined && this.contact.FirstName !== '') {
        name += this.contact.FirstName + ' '
      }
      if (this.contact.LastName !== undefined && this.contact.LastName !== '') {
        name += this.contact.LastName
      }
      return name
    }
  }
}
</script>

<style scoped>
  ul {
    display: inline-block;
  }
  ul li {
    display: block;
  }
</style>

<template>
    <div class="card">
      <div class="card-header">Report</div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md-8 mb-2">
            <b-input-group
              id="reports-search-group"
              label-for="reports-search-input"
              description="Nach Reports suchen"
              size="sm"
              class=""
            >
              <b-form-input
                id="reports-search-input"
                v-model="search"
                type="text"
                placeholder="nach beliebigen Feldern suchen"
                class=""
                debounce="500"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button @click="search = ''" :disabled="search === ''"
                  >Filter entfernen</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-12 col-md-4">
            <b-form-group
              label="Pro Seite"
              label-cols-sm="6"
              label-cols-md="4"
              label-cols-lg="3"
              label-align="right"
              label-align-sm="right"
              label-size="sm"
              label-for="perPageSelect"
              class="mb-0"
            >
              <b-form-select
                v-model="itemsPerPage"
                id="perPageSelect"
                size="sm"
                :options="perPageOptions"
              >
              </b-form-select>
            </b-form-group>
          </div>
          <div class="col-12">
            <b-table
              class="table w-full"
              striped
              hover
              :filter="search"
              :fields="fields"
              :items="items"
              :per-page="itemsPerPage"
              :current-page="currentPage"
            >
              <template v-slot:cell(Id)="data">{{ data.item.Id }}</template>
              <template v-slot:cell(ResultId)="data">{{data.item.ResultId}}</template>
              <template v-slot:cell(DomainId)="data">{{
                data.item.DomainId
              }}</template>
              <template v-slot:cell(Url)="data">
                <a :href="data.item.Url" target="_blank">
                  {{ data.item.Url }}
                </a>
              </template>
              <template v-slot:cell(User)="data">
                <router-link :to="`/users/${data.item.UserId}`">{{ data.item.Username }}</router-link>
              </template>
              <template v-slot:cell(Text)="data">{{ data.item.Text }}</template>
              <template v-slot:cell(CreatedAt)="data">{{
                new Date(data.item.CreatedAt).toLocaleString()
              }}</template>
              <template v-slot:cell(Resolved)="data">
                <b-form-checkbox
                  :id="'checkbox-' + data.item.Id"
                  v-model="data.item.Resolved"
                  :name="'checkbox-' + data.item.Id"
                  value="true"
                  unchecked-value="false"
                >
                </b-form-checkbox>
              </template>
            </b-table>
          </div>
          <div class="col-12">
            <b-pagination
              class="d-flex"
              v-model="currentPage"
              :total-rows="rowsTotal"
              :per-page="itemsPerPage"
              align="right"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'

export default {
  name: 'Templates',
  components: {},
  data: function () {
    return {
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'ResultId',
          label: 'Result Id',
          sortable: true
        },
        {
          key: 'DomainId',
          label: 'Domain Id',
          sortable: true
        },
        {
          key: 'Url',
          label: 'Url',
          sortable: true
        },
        {
          key: 'Text',
          label: 'Text',
          sortable: true
        },
        {
          key: 'User',
          label: 'User',
          sortable: true
        },
        {
          key: 'CreatedAt',
          label: 'CreatedAt',
          sortable: true
        },
        {
          key: 'Resolved',
          label: 'Resolved'
        }
      ],
      search: '',
      currentPage: 1,
      rowsTotal: 0,
      itemsPerPage: 25,
      perPageOptions: [10, 25, 50, 100],
      status: false
    }
  },
  methods: {
    items (ctx, callback) {
      let q = 'crawler/api/reports'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&filter=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDesc
      axios
        .get(q)
        .then((res) => {
          const items = res.data.Results
          this.rowsTotal = res.data.RecordsFiltered
          callback(items)
        })
        .catch((error) => {
          console.log(error)
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    }
  }
}
</script>

<style scoped>
.description {
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #707070;
  opacity: 1;
  margin-bottom: 1rem;
  width: 40%;
}
</style>

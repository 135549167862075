<template>
<div class="card">
  <div class="card-header">Maklerstatistiken</div>
  <div class="card-body">
    <div class="row">
      <div class="col-4">
      </div>
      <div class="col-8">
        <seller-profile-chart :chart-data="sellerProfileData" :options="sellerProfileOptions"></seller-profile-chart>
        <h4 align="right">MUSTER</h4>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import SellerProfileChart from '../../charts/SellerProfileChart'
export default {
  name: 'SellerProfileStatistics',
  components: {
    'seller-profile-chart': SellerProfileChart
  },
  data () {
    return {
      sellerProfileData: null,
      sellerProfileOptions: null
    }
  },
  methods: {
    fillData () {
      this.sellerProfileData = {
        labels: [
          'Valide Makler', 'Invalide Makler', 'Anonyme Makler'
        ],
        datasets: [
          {
            data: [120, 240, 98],
            backgroundColor: [
              'rgb(0,255,14)',
              'rgb(255,251,5)',
              'rgb(255,0,22)'
            ]
          }
        ]
      }
    }
  },
  mounted () {
    this.fillData()
  }
}
</script>

<style scoped>

</style>

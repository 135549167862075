<template>
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        Makler Profile
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-8">
            <advanced-search v-model="search"></advanced-search>
          </div>
          <div class="col-12 col-lg-4">
            <per-page-select v-model="pagination"></per-page-select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <b-table id="sp-table" ref='spTable' :items="items" :fields="fields" :per-page="pagination.perPage" :current-page="pagination.currentPage" :filter="search" striped>
              <template v-slot:cell(Id)="data">
                <router-link :to="'/seller_profiles/'+data.item.Id">{{ data.item.Id }}</router-link>
              </template>
              <template v-slot:cell(Name)="data">
                <div class="row">
                  <div class="col-12">
                    <a v-if="data.item.Name && data.item.Name !== ''" :href="'https://immopark.de/makler/'+data.item.Id" target="_blank" class="ellipsis">
                      <b-icon-house class="mr-1"></b-icon-house><span>{{ data.item.Name }}</span>
                    </a>
                  </div>
                  <div class="col-12">
                    <router-link v-if="data.item.User !== null" :to="'/users/' + data.item.User.Id" class="ellipsis">
                      <b-icon-person class="mr-1"></b-icon-person><span v-if="data.item.User.FirstName || data.item.User.LastName">{{ data.item.User.FirstName }} {{ data.item.User.LastName }}</span>
                      <span v-else>{{ data.item.User.Email }}</span>
                    </router-link>
                  </div>
                  <div class="col-12">
                    <a v-if="data.item.Zip || data.item.City" :href="'https://www.google.de/maps/search/' + getAddress(data.item)"
                       target="_blank" v-b-tooltip="'Auf Google Maps suchen'" class="ellipsis">
                      <b-icon-geo-alt class="mr-1"></b-icon-geo-alt><span>{{ data.item.Zip }} {{ data.item.City }}</span>
                    </a>
                  </div>
                </div>
              </template>
              <template v-slot:cell(FtpPasswd)="data">
                <b-icon-check v-if="data.item.FtpPasswd" variant="success" scale="1.5"></b-icon-check>
                <b-icon-x v-else variant="danger" scale="1.5"></b-icon-x>
              </template>
              <template v-slot:cell(ImportStopped)="data">
                <b-badge class="d-flex" :variant="data.item.ImportStopped ? 'danger' : 'success'">
                  {{ data.item.ImportStopped ? 'Gestoppt' : 'Läuft' }}
                </b-badge>
                <a v-if="data.item.ImportStopped" @click="startImport(data.item)">
                  <b-icon-play-fill></b-icon-play-fill>Start
                </a>
                <a v-else @click="stopImport(data.item)">
                  <b-icon-stop-fill></b-icon-stop-fill>
                  Stop blaa
                </a>
              </template>
              <template v-slot:cell(Validation)="data">
                <div class="validation-ok" v-if="validate(data.item).ok">
                  <b-icon-check>
                  </b-icon-check>
                  <b>OK</b>
                </div>
                <div class="validation-failed" v-else v-for="item in validate(data.item).errors" :key="item">
                  <small>
                    <b-icon-x class="mr-1"></b-icon-x>
                    {{ item }}
                  </small>
                </div>
              </template>
              <template v-slot:cell(Functions)="data">
                <a href="#" class="m-0 danger" @click="deleteSellerProfile(data.item)"><b-icon-trash-fill class="mr-1"></b-icon-trash-fill>Löschen</a>
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <b-pagination
              v-model="pagination.currentPage"
              :total-rows="rowsFiltered"
              :per-page="pagination.perPage"
              aria-controls="sp-table"
              align="right"
              size="sm"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mutations } from '@/main'
import PaginationMixin from '@/components/PaginationMixin'
import AdvancedSearch from '@/components/shared/AdvancedSearch'
import PerPageSelect from '@/components/shared/PerPageSelect'

export default {
  name: 'seller-profiles',
  components: {
    PerPageSelect,
    AdvancedSearch
  },
  mixins: [
    PaginationMixin
  ],
  data () {
    return {
      search: '',
      searchByFields: '',
      searchById: '',
      orderBy: 'id',
      desc: false,
      fields: [
        {
          key: 'Id',
          label: 'Id',
          sortable: true
        },
        {
          key: 'Name',
          label: 'Firma',
          sortable: true
        },
        {
          key: 'FtpPasswd',
          label: 'FTP',
          sortable: true
        },
        {
          key: 'Validation',
          label: 'Validierung',
          sortable: false
        },
        {
          key: 'Functions',
          label: 'Optionen'
        }
      ],
      validationErrors: [
        {
          key: 'companyName',
          label: 'Ungültiger Firmenname'
        },
        {
          key: 'imprint',
          label: 'Ungültiges Impressum'
        },
        {
          key: 'logo',
          label: 'Fehlendes Firmenlogo'
        }],
      sellerProfileId: 0,
      currentSellerProfile: {},
      rowsTotal: 0,
      rowsFiltered: 0
    }
  },
  computed: {
    searchEmpty () {
      return (this.searchByFields === '' && this.searchById === '')
    }
  },
  watch: {
    searchByFields: function () {
      if (this.searchEmpty) {
        this.search = ''
        return
      }
      if (this.searchByFields) {
        this.search = this.searchByFields
        this.searchById = ''
      }
    },
    searchById: function () {
      if (this.searchEmpty) {
        this.search = ''
        return
      }
      if (this.searchById) {
        this.search = 'id:' + this.searchById
        this.searchByFields = ''
      }
    }
    /* currentPage: function () {
      this.$refs.spTable.refresh()
    } */
  },
  methods: {
    resetSearch () {
      this.searchByFields = ''
      this.searchById = ''
      this.search = ''
    },
    startImport: function (item) {
      axios.get(process.env.VUE_APP_API_PATH + '/seller_profiles/' + item.Id + '/enable_import')
        .then(() => {
          mutations.setSuccess('Import erfolreich gestart für ' + item.Name)
        })
        .catch(error => {
          mutations.setAlert(error)
        })
        .finally(this.refresh())
    },
    stopImport: function (item) {
      axios.get(process.env.VUE_APP_API_PATH + '/seller_profiles/' + item.Id + '/disable_import')
        .then(() => {
          mutations.setSuccess('Import erfolreich gestoppt für ' + item.Name)
        })
        .catch(error => {
          mutations.setAlert(error)
        })
        .finally(this.refresh())
    },
    refresh: function () {
      this.$refs.spTable.refresh()
    },
    items (ctx, callback) {
      let q = '/seller_profiles'
      q += '?start=' + (ctx.currentPage - 1) * ctx.perPage
      q += '&length=' + ctx.perPage
      if (ctx.filter) {
        q += '&search=' + ctx.filter
      }
      q += '&order_by=' + ctx.sortBy
      q += '&desc=' + ctx.sortDesc
      axios.get(q)
        .then(res => {
          const items = res.data.data
          this.rowsFiltered = res.data.recordsFiltered
          this.rowsTotal = res.data.recordsTotal
          callback(items)
        })
        .catch((error) => {
          mutations.setAlert(error)
          // eslint-disable-next-line standard/no-callback-literal
          callback([])
        })
      // Must return null or undefined to signal b-table that callback is being used
      return null
    },
    deleteSellerProfile: function (item) {
      const awn = this.$awn
      awn.confirm(
        'Der Makler ' + item.Name + ' (' + item.Id + ') wird endgültig gelöscht?',
        () => {
          axios.delete('/seller_profiles/' + item.Id)
            .then((response) => {
              mutations.setSuccess('Makler ' + item.Name + ' (' + item.Id + ')' + ' erfolgreich gelöscht')
            })
            .catch((error) => {
              mutations.setAlert(error)
            })
            .finally(this.refresh())
        },
        null,
        {
          labels: {
            confirm: 'Sind Sie sicher?',
            ok: 'Ja',
            cancel: 'Nein'
          }
        })
    },
    getAddress: function (item) {
      const vals = [
        item.Street,
        item.HouseNumber,
        item.Zip,
        item.City,
        item.Country
      ]
      return Object.values(vals).join(',')
    },
    validate (sp) {
      const validation = {
        ok: true,
        errors: []
      }
      if (sp.Name.length < 3) {
        validation.ok = false
        validation.errors.push('Firmenname')
      }
      if (sp.Imprint.length < 25) {
        validation.ok = false
        validation.errors.push('Impressum')
      }
      if (sp.LogoFileName.length < 3) {
        validation.ok = false
        validation.errors.push('Logo')
      }
      return validation
    },
    validationTooltip (val) {
      let html = '<ul>'
      val.errors.forEach((e) => {
        html += '<li>' + e + '</li>'
      })
      html += '</ul>'
      return html
    }
  },
  mounted () {
  }
}
</script>

<style scoped>
  .validation-ok {
     display: block;
     color: #00a500;
   }
  .validation-failed {
    display: flex;
    font-weight: normal;
    color: #ff0000;
  }
</style>
